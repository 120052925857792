import React from "react";
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { makeStyles } from '@mui/styles';
import theme from "../layout/theme";
import { Button } from "@mui/material";

interface Props {
  label: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const ControlledStartButton = (props: Props) => {
  const useStyles = makeStyles(() => ({
    "@keyframes active": {
      "0%": {
        color: "#fff",
      },
      "50%": {
        color: theme.palette.secondary.light,
      },
      "100%": {
        color: "#fff",
      },
    },
    "@keyframes start": {
      "0%": {
        transform: "scale(1)",
      },
      "50%": {
        transform: "scale(1.2)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
    root: {
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.875rem",
      },
      "&:hover": {
        background: "none",
        color: theme.palette.secondary.light,
      },
      animationDuration: "1.2s",
      animationIterationCount: "infinite",
      animationName: "$active",
      animationTimingFunction: "ease-in-out",
      background: "none",
      fontSize: "1rem",
      fontWeight: "800",
      letterSpacing: "0.05rem",
    },
    start: {
      animationDuration: "1.2s",
      animationIterationCount: "infinite",
      animationName: "$start",
      animationTimingFunction: "ease-out",
    },
  }));

  const classes = useStyles();

  return (
    <Button
      onClick={props.onClick}
      className={classes.root}
      endIcon={
        <PlayArrowRoundedIcon
          className={classes.start}
          style={{ fontSize: 24 }}
        />
      }
    >
      {props.label}
    </Button>
  );
};

export default ControlledStartButton;
