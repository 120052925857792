import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '../../common/link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material';
import Social from './social';
import { makeStyles } from '@mui/styles';
import theme from '../theme';
import { icoAddress, tokenAddress } from '../../../wallet/abi';

const useStyles = makeStyles({
    button: {
        color: 'white',
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.secondary.light
        }
    }
});


function Copyright(props: any) {
    const classes = useStyles();
    return (
        <Typography variant="body2" color="white" align="center" {...props}>
            {'©All rights reserved by bocoin.info '}
            {/* <Link color="inherit" href="#" underline="none">
                BO Currency
            </Link>{' '} */}
            {new Date().getFullYear()}
            {''}
        </Typography>
    );
}

const footers = [
    {
        title: 'Company',
        description: [
            // {
            //     title: "Blockchain explorer",
            //     to: "http://bocoin.info/blockchain-explore/",
            //     link: true,
            // },
            
            {
                title: " Token details",
                to: "http://bocoin.info/portfolio-items/token-details-demo/",
                link: true,
            },
            {
                title: " Team",
                to: "http://bocoin.info/team/",
                link: true,
            },
            {
                title: " Labs",
                to: "http://bocoin.info/portfolio-items/labs/",
                link: true,
            },
            {
                title: " Careers",
                to: "http://bocoin.info/careers/",
                link: true,
            },
            {
                title: " Frozens",
                to: "http://bocoin.info/frozens/",
                link: true,
            },
            {
                title: " About",
                to: "http://bocoin.info/wp-content/uploads/2022/03/BOC-About-1.pdf",
                link: true,
            },
        ],
    },
    {
        title: 'Features',
        description: [
            {
                title: "Follow Us",
                to: "followus",
                link: false,
            },
            {
                title: "AirDrop",
                to: "airdrop",
                link: false,
            },
            {
                title: "Buy",
                to: "bytoken",
                link: false,
            },

            {
                title: "Invite",
                to: "invite",
                link: false,
            }
        ],
    },
    {
        title: 'CONTACT US TODAY',
        description: [
            // {
            //     title: "Contract",
            //     to: `https://rinkeby.etherscan.io/address/${icoAddress}`,
            //     link: true,
            // },
            {
                title: "News",
                to: "http://bocoin.info/portfolio-items/news/",
                link: true,
            },
            {
                title: "Blockchain explore",
                to: `https://bscscan.com/token/${tokenAddress}`,
                link: true,
            },
            {
                title: "Contract",
                to: `https://bscscan.com/token/${tokenAddress}`,
                link: true,
            }
            // {
            //     title: "Qr",
            //     to: "http://bocoin.info/portfolio-items/maine-modernity-2/",
            //     link: true,
            // },
        ],
    },
    {
        title: 'ABOUT US',
        description: [
            {
                title: "BOC",
                to: "https://bocoin.info/about-2/",
                link: true,

            },
            // {
            //     title: "Buy coin",
            //     to: "http://bocoin.info/get-quote-2/",
            //     link: true,

            // },
            // {
            //     title: "Airdrop",
            //     to: "http://bocoin.info/construction-articles-2/",
            //     link: true,

            // },
            {
                title: "White paper",
                to: "http://bocoin.info/wp-content/uploads/2022/05/BOC-whitepaper.pdf",
                link: true,

            },
            {
                title: "Roadmap",
                to: "http://bocoin.info/wp-content/uploads/2022/04/BOC-roadmap-2.pdf",
                link: true,

            },
            {
                title: "Announcement",
                to: "http://bocoin.info/?page_id=1289",
                link: true,
            },
            {
                title: "Training",
                to: "http://bocoin.info/training/",
                link: true,
            },

        ],
    },
];

function Content() {
    const classes = useStyles();
    const theme = useTheme();

    const handleClick = (route: string) => {
        setTimeout(() => {
            document.getElementById(route)?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
        }, 1);
    }

    return (
        <div
            style={{ backgroundColor: theme.palette.primary.main }}
        >
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            {/* Footer */}
            <Container
                maxWidth="lg"
                component="footer"
                sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                    py: [3, 6],
                }}
            >
                <Social />
                <Grid container spacing={4} justifyContent="space-evenly">
                    {footers.map((footer) => (
                        <Grid item xs={6} sm={3} key={footer.title}>
                            <Typography variant="h6" color="white" gutterBottom>
                                {footer.title}
                            </Typography>
                            <ul>
                                {footer.description.map((item, idx) => (
                                    <li key={idx}>
                                        {
                                            item.link ?
                                                <div onClick={() => window.open(item.to)}>
                                                    <Typography variant="subtitle2" className={classes.button}>
                                                        {item.title}
                                                    </Typography>
                                                </div>
                                                :
                                                <div onClick={() => handleClick(item.to)}>
                                                    <Typography variant="subtitle2" className={classes.button}>
                                                        {item.title}
                                                    </Typography>
                                                </div>
                                        }

                                    </li>
                                ))}
                            </ul>
                        </Grid>
                    ))}
                </Grid>
                <Copyright sx={{ mt: 5 }} />
            </Container>
            {/* End footer */}
        </div >
    );
}

export default function Footer() {
    return <Content />;
}