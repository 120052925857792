import React from 'react'
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { CardContent, Container, Grid, Card, Typography } from '@mui/material';
import theme from '../layout/theme';
import FormButton from '../common/formButton';
import { BlockchainDispatchContext, BlockchainStateContext } from '../../context/BlockchainContext';
import * as actions from "../../context/store/actionTypes";
import { selectedColor } from '../../app/uiSlice';
import { useAppSelector } from '../../app/hooks';

const useStyles = makeStyles({
    root: {
        justifyContent: "center",
        minHeight: "30em",
        overflow: "hidden",
        position: "relative",
        textAlign: "center",
        alignItems: "center",
        height: "auto",
    },
    image: {
        transition: 'transform .4s',
        "&:hover": {
            transform: 'scale(1.03)',
        },
    },
});


export default function FollowUs() {
    const classes = useStyles();
    const communicatorDispatch = React.useContext(BlockchainDispatchContext);
    const uiStatus = useAppSelector(selectedColor);
    const { isFollowInstagram, isFollowTelegram, isFollowTwitter } = React.useContext(BlockchainStateContext);

    return (
        <Container maxWidth="xl">
            <Card sx={{
                pt: 2,
                backgroundColor: "transparent"
            }}>
                <CardContent sx={{
                    marginBottom: theme.spacing(3),
                    border: "1px solid",
                    borderColor: uiStatus === "dark" ? "white" : "black",
                    borderRadius: theme.spacing(1)
                }}>
                    <Grid container spacing={5} className={classes.root} id="followus" sx={{ mt: 8 }}>
                        <Grid item sm={6} xs={12} justifyContent='center' display={'flex'}>
                            <Container>
                                <Typography
                                    component="h4"
                                    variant="h4"
                                    align="left"
                                    color={uiStatus === "dark" ? "white" : "black"}
                                    gutterBottom
                                >
                                    Follow us
                                </Typography>
                                <Typography variant="subtitle1" align="left"
                                    color={uiStatus === "dark" ? "white" : "black"}
                                    component="p">
                                    To claim your airdrop it’s important to follow us on social media apps. You can know about our news and events in our social media.
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        p: 1,
                                        m: 1,
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="subtitle1"
                                            color={uiStatus === "dark" ? "white" : "black"}
                                        >
                                            Please follow us on Instagram
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <FormButton
                                            title='Instagram'
                                            onClick={() => {
                                                window.open("http://www.instgram.com/bocoinofficial")
                                                communicatorDispatch({
                                                    type: actions.IS_FOLLOW_INSTAGRAM,
                                                    payload: true,
                                                });
                                            }}
                                            startIcon={<InstagramIcon />}
                                            background={isFollowInstagram ? "green" : "gray"}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        p: 1,
                                        m: 1,
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="subtitle1"
                                            color={uiStatus === "dark" ? "white" : "black"}
                                            gutterBottom component="div">
                                            Please follow us on Telegram
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <FormButton
                                            title='Telegram'
                                            onClick={() => {
                                                window.open("https://t.me/bocurrency")
                                                communicatorDispatch({
                                                    type: actions.IS_FOLLLOW_TELEGRAM,
                                                    payload: true,
                                                });
                                            }}
                                            startIcon={<TelegramIcon />}
                                            background={isFollowTelegram ? "green" : "gray"}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        p: 1,
                                        m: 1,
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography variant="subtitle1"
                                            color={uiStatus === "dark" ? "white" : "black"}
                                            gutterBottom component="div">
                                            Please follow us on Twitter
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <FormButton
                                            title='Twitter'
                                            onClick={() => {
                                                window.open("https://twitter.com/BoCurrency?s=20&t=t-PXm_2zHI1M90dinrH5DQ")
                                                communicatorDispatch({
                                                    type: actions.IS_FOLLOW_TWITTER,
                                                    payload: true,
                                                });
                                            }}
                                            startIcon={<TwitterIcon />}
                                            background={isFollowTwitter ? "green" : "gray"}
                                        />
                                    </Box>
                                </Box>
                            </Container>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <img
                                className={classes.image}
                                src={"/images/Share_link_pana.png"}
                                alt="Asset"
                                height="70%"
                                width="70%"
                            />
                        </Grid>
                        <Grid item xs={12}>

                        </Grid>
                    </Grid>
                </CardContent>
            </Card >
        </Container >
    )
}
