import * as React from 'react';
import Stack from '@mui/material/Stack';
import { Button as MuiButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '../layout/theme';
import LoadingIndicator from './loadingIndicator';
import { selectedColor } from '../../app/uiSlice';
import { useAppSelector } from '../../app/hooks';

const useStyles = makeStyles({
    container: {
        borderColor: theme.palette.secondary.light,
        border: "1px solid",
        borderRadius: 10,
        minWidth: '10rem',
    },
    root: {
        height: 48,
        '&.css-rf1mhg-MuiButtonBase-root-MuiButton-root': {
            color: 'white',
        },
        "&:hover": {
            color: theme.palette.secondary.light,
        },
        "&.css-rf1mhg-MuiButtonBase-root-MuiButton-root.Mui-disabled": {
            color: "gray"
        }
    },
});

interface Props {
    title: String;
    startIcon?: any;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    loading?: boolean;
    disabled?: boolean
}

export default function Button(props: Props) {
    const classes = useStyles();
    const uiStatus = useAppSelector(selectedColor);

    return (
        <Stack spacing={2}
            className={classes.container}
        >
            <MuiButton
                onClick={props.onClick}
                className={classes.root}
                style={{
                    color: uiStatus === "dark" ? "white" : "black"
                }}
                disabled={props.disabled || props.loading}
                startIcon={
                    props.startIcon &&
                        props.loading
                        ? <LoadingIndicator />
                        : props.startIcon}
            >{props.title}
            </MuiButton>
        </Stack >
    );
}