import * as React from 'react';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Button from '../common/button';
import CountDown from '../common/countDown';
import { Box, Card, CardContent, Container, Typography } from '@mui/material';
import theme from '../layout/theme';
import { BlockchainDispatchContext, BlockchainStateContext } from '../../context/BlockchainContext';
import { ethers } from 'ethers';
import { icoAbi, icoAddress, tokenAbi, tokenAddress } from '../../wallet/abi';
import Dialog from '../common/dialog';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import * as actions from "../../context/store/actionTypes";
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import { selectedColor } from '../../app/uiSlice';
import { useAppSelector } from '../../app/hooks';

const useStyles = makeStyles({
    root: {
        justifyContent: "center",
        minHeight: "30em",
        overflow: "hidden",
        position: "relative",
        textAlign: "center",
        alignItems: "center",
        height: "auto",
    },
    image: {
        transition: 'transform .4s',
        "&:hover": {
            transform: 'scale(1.03)',
        },
    },
});

function AirDrop() {

    const handleClick = (route: string) => {
        // if (mobileMoreAnchorEl) handleMobileMenuClose();
        setTimeout(() => {
            document.getElementById(route)?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
        }, 1);
    }

    const classes = useStyles();

    const { library, account, deadLineDate, referAddress,
        isFollowInstagram, isFollowTelegram, isFollowTwitter
    } = React.useContext(BlockchainStateContext);
    const uiStatus = useAppSelector(selectedColor);

    const [loading, setLoading] = React.useState<boolean>(false)
    const [open, setOpen] = React.useState<boolean>(false)
    const communicatorDispatch = React.useContext(BlockchainDispatchContext);
    const [text, setText] = React.useState<string>()
    const [firstF, setFirstF] = React.useState<string>()


    const claim = async () => {
        
        try {
        
        if(!isFollowInstagram){
            // setFirstF("Please follow us on social medias.")
            setOpen(true)
                setText("Please follow us on social medias.")
                setLoading(false)
        }else{
        if (library) {
            setLoading(true)
            const icoContract = new ethers.Contract(icoAddress, icoAbi, library)
            const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, library)
            const tokenBalance = await tokenContract.balanceOf(account)
            const isStarted = await icoContract.isStarted()
            if(!isStarted){
                setOpen(true)
                setText("Airdrop is off")
                setLoading(false)
            }else{
            const airdropHistory = await icoContract.airdropHistory()
            if(parseInt(airdropHistory) >= 30000000*10**18){
                setOpen(true)
                setText("Airdrop token's end .")
                setLoading(false)
            }else{
            const isGot = await icoContract.isGotAirdrop(account)
            if (isGot) {
                setOpen(true)
                setText("you got airdrop before ")
                setLoading(false)
            } else {
                // if (parseInt(tokenBalance) === 0) {
                //     setOpen(true)
                //     setText("please first buy token.")
                //     setLoading(false)
                // } else {
                    const signer = library.getSigner()
                    const res = await icoContract.connect(signer).airdrop(referAddress)
                    const receipt = await res.wait()
                        if(receipt.status==1){
                            setOpen(true)
                            setText("successfully got airdropped.")
                            setLoading(false)
                        }else{
                            setLoading(false)
                        }
                }

            }
        }
      }
    }

    } catch (error) {
      setLoading(false)          
    }
    }

    const showDeadline = async () => {
        if (library) {
            const icoContract = new ethers.Contract(icoAddress, icoAbi, library)
            const deadline = await icoContract.deadline()
            // console.log("deadline :", parseInt(deadline))
            communicatorDispatch({
                type: actions.DEAD_LINE,
                payload: parseInt(deadline),
            });
        }else{
            const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed.binance.org/')
            const icoContract = new ethers.Contract(icoAddress, icoAbi, provider)
            const deadline = await icoContract.deadline()
            communicatorDispatch({
                type: actions.DEAD_LINE,
                payload: parseInt(deadline),
            });
        }
    }

    React.useEffect(() => {
        showDeadline()
        if (library) {
            getRefer()
        }
    }, [library])

    const getRefer = () => {
        var lnkStr = window.location.href;   //document.URL;   
        let index = lnkStr.indexOf('?ref=');
        let referAdd = '';
        if (index >= 0) {
            referAdd = lnkStr.substring(index + 5, index + 5 + 42);
            communicatorDispatch({
                type: actions.REFER_ADDRESS,
                payload: referAdd,
            });
        }
        else {
            referAdd = '0x0000000000000000000000000000000000000000';
            communicatorDispatch({
                type: actions.REFER_ADDRESS,
                payload: referAdd,
            });
        }
    }

    React.useEffect(() => {
        buttonAirDropStatus()
    }, [isFollowInstagram, isFollowTelegram, isFollowTwitter])

    const buttonAirDropStatus = () => {
        if (!isFollowInstagram){
            // setFirstF("Please follow us on social medias.")
            return true
        }
            
        else if (!isFollowTelegram)
            return true
        else if (!isFollowTwitter)
            return true
        else return false
    }
    return (
        <Container maxWidth="xl">
            <Card sx={{
                backgroundColor: "transparent",
            }}>
                <CardContent sx={{
                    marginBottom: theme.spacing(3),
                    border: "1px solid",
                    borderColor: uiStatus === "dark" ? "white" : "black",
                    borderRadius: theme.spacing(1)
                }}>
                    <Grid container spacing={5} className={classes.root} id='airdrop'>
                        <Grid item sm={6} xs={12} alignItems={'flex-start'} justifyContent='center' display={'flex'}>
                            <Card sx={{ width: '100%', background: "none", boxShadow: "none", borderColor: "white" }}>
                                <CardContent>
                                    <Typography
                                        component="h4"
                                        variant="h4"
                                        align="left"
                                        color={uiStatus === "dark" ? "white" : "black"}
                                        gutterBottom
                                    >
                                        Claim your airdrop
                                    </Typography>
                                    <Typography variant="subtitle1" align="left"
                                        color={uiStatus === "dark" ? "white" : "black"}
                                        component="p">
                                        Receive your airdrop before it’s end . So what are you waiting for ? get your 100 BOC tokens.
                                    </Typography>
                                </CardContent>
                                <Box display={'flex'} justifyContent={'right'} padding={2}>
                                    <Button
                                        startIcon={<ShoppingCartRoundedIcon />}
                                        onClick={claim}
                                        loading={loading}
                                        // disabled={buttonAirDropStatus()}
                                        title='Claim Airdrop'
                                    />
                                </Box><br/>
                                    {/* <Typography>{firstF}</Typography> */}
                            </Card>
                        </Grid>
                        <Grid item sm={6} xs={12} alignItems={'center'} justifyContent='center' display={'flex'}>
                            <img
                                className={classes.image}
                                src={"/images/Send_gift-rafiki.png"}
                                alt="Asset"
                                height="70%"
                                width="70%"
                            />  
                        </Grid>
                        <Grid item xs={12} alignItems={'center'} justifyContent='center' display={'flex'}>
                            <Card sx={{ width: '100%', background: "none", boxShadow: "none" }}>
                                <CardContent>
                                    <Typography
                                        variant="h4"
                                        align="center"
                                        color={uiStatus === "dark" ? "white" : "black"}
                                        gutterBottom
                                        component="p"
                                    >
                                        airdrop ends in
                                    </Typography>
                                </CardContent>
                                <Box display={'flex'} justifyContent={'center'} padding={2}>
                                    {deadLineDate &&
                                        <CountDown
                                            startTime={deadLineDate}
                                        />
                                    }
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                header={'airDrop'}
                content={
                    <Typography
                        variant="h4"
                        align="center"
                        color={uiStatus === "dark" ? "white" : "black"}
                        gutterBottom
                        component="p"
                    >
                        {text}
                    </Typography>
                }
                actions={<>
                    <Button
                        onClick={() => setOpen(false)}
                        title='ok'
                        startIcon={<CheckRoundedIcon />}
                    />
                </>}
            />
        </Container>
    );
}

export default AirDrop;
