import React from "react";
import { Input as MuiInput, InputAdornment, Select } from "@mui/material";
import { makeStyles } from '@mui/styles';
import theme from "../layout/theme";
import { selectedColor } from "../../app/uiSlice";
import { useAppSelector } from "../../app/hooks";

const useStyles = makeStyles(() => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: "100%",
    },
    label: {
        fontSize: "small",
    },
    error: {
        fontSize: "small",
    },
    input: {
        border: "1px solid",
        borderColor: theme.palette.secondary.light,
        borderRadius: 10,
        height: "2.7rem",
        position: "relative",
        width: "100%",
        padding: "0 1em",
        letterSpacing: "0.075em",
        fontSize: "14px",
        fontWeight: "400",
        "&:focus": {
            border: 0,
            outline: "none",
        },
        "& .css-1x51dt5-MuiInputBase-input-MuiInput-input.Mui-disabled": {
            WebkitTextFillColor: "gray"
        }
    }
}));

const Input = (props: any) => {
    const {
        name,
        label,
        type = "text",
        value,
        onChange,
        onKeyPress,
        error,
        withOutMargin = false,
        ...rest
    } = props;
    const classes = useStyles();

    const uiStatus = useAppSelector(selectedColor);

    return (
        <div className={classes.root}>
            <label className={classes.label} htmlFor={name}>
                {label}
            </label>
            <MuiInput
                className={classes.input}
                id={name}
                color={'black'}
                style={{
                    color: uiStatus === "dark" ? "white" : "black"
                }}
                name={name}
                type={type}
                value={value}
                onChange={onChange}
                onKeyPress={onKeyPress}
                placeholder={label}
                disableUnderline
                endAdornment={
                    <InputAdornment position="end">
                        {props.endAdornment}
                    </InputAdornment>
                }
                {...rest}
            />
            {error && <div className={classes.error}>{error}</div>}
        </div>
    );
};

export default Input;