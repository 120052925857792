import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import IconButton from '../../common/iconButton'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import TelegramIcon from '@mui/icons-material/Telegram';

export default function Social() {
    return (

        <Box
            sx={{
                display: 'flex',
                alignItems: 'end',
                justifyContent: 'center',
                p: 1,
                m: 1,
                border: '1px solid #9d9d9d',
                borderRadius: 30,
                color: 'red',
                justifyItems: "end",
                '& hr': {
                    mx: 0.5,
                },
                mb: 6,
                padding:2
            }}
        >
            <Typography variant="button" pr={1} style={{ color: 'white' }} display="block" gutterBottom>
                Follow the journey
            </Typography>
            <Divider orientation="vertical" flexItem style={{ backgroundColor: "white" }} />
            <IconButton
                icon={<FacebookRoundedIcon />}
                onClick={() => window.open('https://www.facebook.com/profile.php?id=100079165535572')}
            />
            <IconButton
                icon={<TwitterIcon />}
                onClick={() => window.open('https://twitter.com/BoCurrency?s=20&t=t-PXm_2zHI1M90dinrH5DQ')}
            />
            <IconButton
                icon={<InstagramIcon />}
                onClick={() => window.open('http://www.instgram.com/bocoinofficial')}
            />
            <IconButton
                icon={<TelegramIcon />}
                onClick={() => window.open('https://t.me/bocurrency')}
            />
        </Box>
    )
}
