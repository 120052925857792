import React from "react";
import PropTypes from "prop-types";
import { Link as MuiLink } from '@mui/material'
import { Link as RouterLink } from "react-router-dom";

const Link = (props: { [x: string]: any; children: any; to: any; }) => {
  const { children, to, ...rest } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} {...itemProps} />
      )),
    [to]
  );

  return (
    <MuiLink component={renderLink} {...rest}>
      {children}
    </MuiLink>
  );
};

Link.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  to: PropTypes.string.isRequired,
};

export default Link;
