import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import { selectedColor } from '../../../../app/uiSlice';
import { useAppSelector } from '../../../../app/hooks';

const useStyles = makeStyles({
    root: {
        justifyContent: "center",
        minHeight: "35em",
        overflow: "hidden",
        position: "relative",
        textAlign: "center",
        alignItems: "center"
    },
    "@keyframes slidein": {
        "from": {
            transform: "translateY(0)",
        },
        "to": {
            transform: 'translateY(-2rem)',
        }
    },
    image: {
        animationDuration: "3s",
        animationName: "$slidein",
        animationIterationCount: "infinite",
    },
});

function Second() {
    const classes = useStyles();
    const uiStatus = useAppSelector(selectedColor);

    return (
        <Container maxWidth="xl">
            <Grid container spacing={5} className={classes.root}>
                <Grid item sm={6} xs={12}>
                    <img
                        className={classes.image}
                        src={"/images/illustration_8.png"}
                        alt="Asset"
                        height="70%"
                        width="70%"
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Typography
                        component="h4"
                        variant="h4"
                        align="left"
                        color={uiStatus === "dark" ? "white" : "black"}
                        gutterBottom
                    >
                        What is BO?
                    </Typography>
                    <Typography variant="subtitle1" align="left"
                        color={uiStatus === "dark" ? "white" : "black"}
                        component="p">
                        The first supported currency in the world and active in economic projects.
                        BO is a Cryptocurrency that contains the whole payment services based on blockchain.
                        And it steps toward converting to an international currency which will have support and true value and in line of providing services for financial transactions peer to peer without intermediary intervention with high speed including security and privacy.

                        BO is a peer-to-peer payment system that allows fast and secure payment with low fees and high transaction capacity, It has smoothed the transaction problems in accepting and speeding the transaction in terms of intercourse and validators and dividing the blocks and the size of the blocks. Which will also be presented in the detailed program of the dedicated network and we always emphasize our platform decentralization and its upgrading.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Second