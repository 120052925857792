import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import { selectedColor } from '../../../../app/uiSlice';
import { useAppSelector } from '../../../../app/hooks';

const useStyles = makeStyles({
    root: {
        justifyContent: "center",
        minHeight: "25em",
        overflow: "hidden",
        position: "relative",
        textAlign: "center",
        alignItems: "center",
    },
    image: {
        transition: 'transform .4s',
        "&:hover": {
            transform: 'scale(1.03)',
        },
    },
});

function Fourth() {
    const classes = useStyles();
    const uiStatus = useAppSelector(selectedColor);

    return (
        <Container maxWidth="xl">
            <Grid container spacing={5} className={classes.root}>
                <Grid item sm={6} xs={12}>
                    <img
                        className={classes.image}
                        src={"/images/Creative_team_amico.png"}
                        alt="Asset"
                        height="70%"
                        width="70%"
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <Typography
                        component="h4"
                        variant="h4"
                        align="left"
                        color={uiStatus === "dark" ? "white" : "black"}
                        gutterBottom
                    >
                        What are the economic activities of BO?
                    </Typography>
                    <Typography variant="subtitle1" align="left"
                        color={uiStatus === "dark" ? "white" : "black"}
                        component="p">
                        Real Estate - Investing in high value-added projects in the field of construction and production of residential, commercial and recreational projects
                        Transportation - Investing in air, land, rail, sea transport
                        Modern Banking - Establishing of the first independent economic center in the world
                        Financial - Providing all financial transaction services and cooperation with leading companies in the field of payment services
                        Tourism - Creating a tourism platform and investing in tourism and paying with Boc coins
                        Oil - Cooperation with large companies and sales of oil derivatives
                        Mines - Exploration, Transit and Sales
                        Production - Buying shares of leading companies in the field of production and partnership with them
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Fourth;