import * as React from 'react';
import { Dialog as MuiDialog } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IconButton from './iconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { makeStyles } from '@mui/styles';
import theme from '../layout/theme';

const useStyles = makeStyles({
    dialog: {
        "& .css-d6tvcf-MuiPaper-root-MuiDialog-paper": {
            backgroundColor: `${theme.palette.primary.main} !important`,
        },
    },
    root: {
        background: theme.palette.primary.main,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
        border: "1px solid"
    },
    title: {
        display: "flex",
        fontSize: "medium",
        padding: 0,
        justifyContent: "space-between",
        alignItems: 'center',
    },
    content: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflow: "auto",
        padding: theme.spacing(2, 1),
        whiteSpace: "pre-line",
    },
    actions: {
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-end",
    },
});

interface Props {
    open: boolean;
    header: string;
    content: any;
    actions?: any;
    onClose?: React.MouseEventHandler<HTMLButtonElement>;
}
export default function Dialog(props: Props) {
    const classes = useStyles();

    return (
        <MuiDialog
            className={classes.dialog}
            open={props.open}
            onClose={props.onClose}
        >
            <div className={classes.root}>
                <div
                    className={classes.title}>
                    {props.header}
                    <IconButton
                        icon={<CloseRoundedIcon fontSize="small" />}
                        onClick={props.onClose}
                    />
                </div>
                <div className={classes.content}>
                    {props.content}
                </div>
                {props.actions && <div className={classes.actions}>{props.actions}</div>}
            </div>
        </MuiDialog>
    );
}