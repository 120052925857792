import * as actions from "./actionTypes";

export const initialState = {
  library: "",
  account: localStorage.getItem("ACCOUNT"),
  walletName: localStorage.getItem("WALLET_NAME"),
  referAddress: "",
  deadLineDate: undefined,
  isAdmin: undefined,
  isFollowInstagram: false,
  isFollowTelegram: false,
  isFollowTwitter: false,
};

const blockchainReducer = (state, action) => {
  switch (action.type) {
    case actions.BLOCK_CHAIN_LIBRARY:
      return {
        ...state,
        library: action.payload,
      };
    case actions.BLOCK_CHAIN_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };
    case actions.WALLET_NAME:
      return {
        ...state,
        walletName: action.payload,
      };
    case actions.REFER_ADDRESS:
      return {
        ...state,
        referAddress: action.payload,
      };
    case actions.DEAD_LINE:
      return {
        ...state,
        deadLineDate: action.payload,
      };
    case actions.IS_ADMIN:
      return {
        ...state,
        isAdmin: action.payload,
      };
    case actions.IS_FOLLOW_INSTAGRAM:
      return {
        ...state,
        isFollowInstagram: action.payload,
      };
    case actions.IS_FOLLLOW_TELEGRAM:
      return {
        ...state,
        isFollowTelegram: action.payload,
      };
    case actions.IS_FOLLOW_TWITTER:
      return {
        ...state,
        isFollowTwitter: action.payload,
      };
    default:
      return initialState;
  }
};

export default blockchainReducer;
