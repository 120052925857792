import * as React from 'react';
import Slider from '../../common/slider';
import First from './sections/first';
import Fourth from './sections/fourth';
import Thirth from './sections/thirth ';
import Second from './sections/second';
import AirDrop from '../../airdrop/airDrop';
import BuyToken from '../../buyToken/buyToken';
import Invitation from '../../invitationCode/Invitation';
import FollowUs from '../../followUs/followUs';
import { Box } from '@mui/material';

function Content() {
    return (
        <Box sx={{ pt: 8, pb: 6 }}>
            <First />
            <Slider />
            <Second />
            <Thirth />
            <Fourth />
            <FollowUs />
            <AirDrop />
            <BuyToken />
            <Invitation />
        </Box>
    );
}

export default function Home() {
    return <Content />;
}