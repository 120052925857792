import * as React from 'react';
import { Card as MuiCard } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActions } from '@mui/material';
import { Box } from '@mui/system';
import theme from '../layout/theme';
import { selectedColor } from '../../app/uiSlice';
import { useAppSelector } from '../../app/hooks';

interface Props {
    header?: string;
    title?: any;
    content?: any;
    actions?: any;
    mt?: any
}

export default function Card(props: Props) {
    const uiStatus = useAppSelector(selectedColor);

    return (
        <MuiCard sx={{
            // maxWidth: 345,
            minWidth: "23rem",
            minHeight: '16rem',
            backgroundColor: "transparent",
            border: "1px solid",
            borderColor: uiStatus === "dark" ? "white" : "black",
            borderRadius: theme.spacing(1),
            p: 1,
            m: 1,
        }}>
            {props.header
                &&
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <Typography variant="h4"
                        color={uiStatus === "dark" ? "white" : "black"}
                        component="div">
                        {props.header}
                    </Typography>
                </Box>
            }
            {props.title
                &&
                <Box sx={{
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <Typography variant="h5"
                        color={uiStatus === "dark" ? "white" : "black"}
                        component="div">
                        {props.title}
                    </Typography>
                </Box>
            }
            <CardContent>
                {props.content}
            </CardContent>
            <CardActions style={{
                justifyContent: "center",
                marginTop: props.mt ? `${props.mt}rem` : "auto"
            }}>
                {props.actions}
            </CardActions>
        </MuiCard >
    );
}