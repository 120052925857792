import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#080f23',
      light: "#ffffff"
    },
    secondary: {
      main: '#0e1525',
      light: "#ffbf09"
    },
    text: {
      primary: "#ffffff",
      secondary: '#21130d'
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
