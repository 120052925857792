import React, { useContext, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import theme from '../theme';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '../../common/iconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Link from '../../common/link';
import Dialog from '../../common/dialog';
import MetaMaskIcon from '../../common/icons/metaMaskIcon';
import WalletConnectIcon from '../../common/icons/walletConnectIcon';
import TrustWalletIcon from '../../common/icons/trustWalletIcon';
import BinanceChainIcon from '../../common/icons/binanceChainIcon';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ControlledStartButton from '../../common/controlledStartButton';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { changedColor, selectedColor } from '../../../app/uiSlice';
import { useWeb3React } from '@web3-react/core';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { injected, bscConnector, ledger } from '../../../wallet/connectors'
import { Tooltip, Typography } from '@mui/material';
import { BlockchainDispatchContext, BlockchainStateContext } from '../../../context/BlockchainContext';
import * as actions from "../../../context/store/actionTypes";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { icoAbi, icoAddress, tokenAddress } from '../../../wallet/abi';
import { ethers } from 'ethers';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { makeStyles } from '@mui/styles';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';


export const useStyles = makeStyles({
    "@keyframes hourglass": {
        "0%,5%": {
            transform: "rotate(360deg)",
        },
        "100%": {
            transform: "rotate(0deg)",
        },
    },
    icon: {
        animationDelay: "0.5s",
        animationDuration: "10s",
        animationIterationCount: "infinite",
        animationName: "$hourglass",
        animationTimingFunction: "ease-out",
    },
    "@keyframes start": {
        "0%": {
            background: "#blue",
        },
        "50%": {
            background: "#green",
        },
        "100%": {
            background: "#blue",
        },
    },
    root: {
        animationName: "$start",
        animationDelay: "0.5s",
        animationDuration: "5s",
        animationIterationCount: "infinite",
        animationTimingFunction: "ease-out",
    },
    list: {
        background: theme.palette.primary.main,
    },
    button: {
        color: 'white',
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.secondary.light
        }
    }
});

export const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        backgroundColor: theme.palette.secondary.light,
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));

export const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        http:"https://bridge.walletconnect.org",
        infuraId: '00a9e68117da45a4ae16de81ab0d59d5',
        bridge: "https://bridge.walletconnect.org",
        rpc: {
          56: 'https://bsc-dataseed.binance.org/',
        },
        qrcode: true,
        chainId: 56,
        rpcUrl: 'https://bsc-dataseed.binance.org/'
      },
    },
    binancechainwallet: {
        package: true
      }
  };

  const web3Modal = new Web3Modal({
      network:"binance",
    cacheProvider: true, // optional
    providerOptions // required
  });



export default function AppBarLayout() {
    const dispatch = useAppDispatch();
    const uiStatus = useAppSelector(selectedColor);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
        React.useState<null | HTMLElement>(null);

    const [open, setOpen] = React.useState(false);
    const [provider, setProvider] = React.useState<any>();
    const [library, setLibrary] = React.useState<any>();
    const [account, setAccount] = React.useState('')
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const classes = useStyles();

    const mobileMenuId = 'primary-search-account-menu-mobile';

    const handleClick = (route: string) => {
        if (mobileMoreAnchorEl) handleMobileMenuClose();
        setTimeout(() => {
            document.getElementById(route)?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" })
        }, 1);
    }

    const handleColor = () => {
        dispatch(changedColor())
    }

    // const { activate, deactivate, active, account, library, connector } = useWeb3React();
    const [walletName1, setWalletName] = React.useState("")
    const communicatorDispatch = useContext(BlockchainDispatchContext);
    const walletData = React.useContext(BlockchainStateContext);

    async function connect(walletName: string) {
        try {
            const provider = await web3Modal.connectTo(walletName)
            setProvider(provider)
            const library = new ethers.providers.Web3Provider(provider)
            const signer = library.getSigner()
            setAccount(await signer.getAddress())
            setLibrary(library)
            localStorage.setItem("WALLET_NAME", walletName)
            setWalletName(walletName)
            setOpen(false)
            if(walletName == "walletconnect"){location.reload()}
        } catch (error) {
            setOpen(false)
        }
    }

    const walletConnect = async (walletName : any) => {
        const provider = await web3Modal.connectTo(walletName)
        setProvider(provider)
        const library = new ethers.providers.Web3Provider(provider)
        const signer = library.getSigner()
        setAccount(await signer.getAddress())
        setLibrary(library)
        localStorage.setItem("WALLET_NAME", walletName)
        setWalletName(walletName)
    }

    useEffect(() => {
        if (library) {
            communicatorDispatch({
                type: actions.BLOCK_CHAIN_LIBRARY,
                payload: library,
            });
            communicatorDispatch({
                type: actions.BLOCK_CHAIN_ACCOUNT,
                payload: account,
            });
            communicatorDispatch({
                type: actions.WALLET_NAME,
                payload: walletName1,
            });
            showOwner()
            changeNetwork()
        }
    }, [library, account])


    // useEffect(() => {
    //     if (provider?.on) {

    //       const handleDisconnect = () => {
    //         console.log("disconnect");
    //         web3Modal.clearCachedProvider()
    //         localStorage.clear();
    //         communicatorDispatch({
    //             type: actions.BLOCK_CHAIN_LIBRARY,
    //             payload: "",
    //         });
    //         communicatorDispatch({
    //             type: actions.BLOCK_CHAIN_ACCOUNT,
    //             payload: "",
    //         });
    //         communicatorDispatch({
    //             type: actions.WALLET_NAME,
    //             payload: "",
    //         });
    //         location.reload()
    //       };
    

    //       provider.on("disconnect", handleDisconnect);
    
    //       return () => {
    //         if (provider.removeListener) {

    //           provider.removeListener("disconnect", handleDisconnect);
    //         }
    //       };
    //     }
    //   }, [provider]);


    React.useEffect(() => {
        const wallet = localStorage.getItem("WALLET_NAME")
            switch (wallet) {
                case "injected":
                    connect("injected")
                    changeNetwork()              
                    break;
                case "walletconnect":
                    walletConnect("walletconnect")
                    break;
                case "binancechainwallet":
                    connect("binancechainwallet")
                    break;
            }
    },[])



    

    function disconnect() {
        if(library){
        try {
            web3Modal.clearCachedProvider()
            localStorage.clear();
            communicatorDispatch({
                type: actions.BLOCK_CHAIN_LIBRARY,
                payload: "",
            });
            communicatorDispatch({
                type: actions.BLOCK_CHAIN_ACCOUNT,
                payload: "",
            });
            communicatorDispatch({
                type: actions.WALLET_NAME,
                payload: "",
            });
            location.reload()
        } catch (error) {

        }
    }
    }

    const showOwner = async () => {
        if (library) {
            const icoContract = new ethers.Contract(icoAddress, icoAbi, library)
            const owner = await icoContract.owner()
            communicatorDispatch({
                type: actions.IS_ADMIN,
                payload: owner === account ? true : false,
            });
        }
    }

    const changeNetwork = () => {
        const wallet = localStorage.getItem("WALLET_NAME")
        if(window.ethereum && wallet == "injected" ){
            console.log("changeNetwork")
        window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [{
                chainId: "0x38",
                rpcUrls: ["https://bsc-dataseed.binance.org/"],
                chainName: "Binance Mainnet",
                nativeCurrency: {
                    name: "BNB",
                    symbol: "BNB",
                    decimals: 18
                },
                blockExplorerUrls: ["https://bscscan.com"]
            }]
        });
      }
    }
    


    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            classes={{ list: classes.list }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                {walletData?.isAdmin &&
                    <Link to="/management">
                        <span className={classes.button}>
                            Management
                        </span>
                    </Link>
                }
            </MenuItem>
            <MenuItem className={classes.button} onClick={() => handleClick("followus")}>
                Follow Us
            </MenuItem>
            <MenuItem className={classes.button} onClick={() => handleClick("airdrop")}>
                Airdrop
            </MenuItem>
            <MenuItem className={classes.button} onClick={() => handleClick("bytoken")}>
                Buy
            </MenuItem>
            <MenuItem className={classes.button} onClick={() => handleClick("invite")}>
                Invite
            </MenuItem>
            <MenuItem className={classes.button}  onClick={() => window.open(`https://bscscan.com/token/${tokenAddress}`)}>
                Blockchain explorer
            </MenuItem>
            <MenuItem className={classes.button} onClick={() => window.open(`https://bscscan.com/token/${tokenAddress}`)}>
                contract
            </MenuItem>
            <MenuItem className={classes.button} onClick={() => window.open(`http://bocoin.info/wp-content/uploads/2022/04/BOC-roadmap-2.pdf`)}>
                Roadmap
            </MenuItem>
            <MenuItem className={classes.button} onClick={() => window.open(`http://bocoin.info/wp-content/uploads/2022/05/BOC-whitepaper.pdf`)}>
                whitepaper
            </MenuItem>
        </Menu >
    );

    var windowSize = "width=" + window.innerWidth + ",height=" + window.innerHeight + ",scrollbars=no";
    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar
                    position="fixed"
                    color="secondary"
                    elevation={0}
                >
                    <Toolbar sx={{ flexWrap: 'wrap', justifyContent: "center" }}>
                        <Box sx={{
                            flex: { xs: 1, sm: 1, md: 'inherit' }
                        }} mr={2}>
                            <Link to="/">
                                <img
                                    src={"/images/logo.png"}
                                    alt="logo"
                                    width="70rem"
                                    height="auto"
                                />
                            </Link>
                        </Box>
                        <Box flex={1} sx={{ display: { xs: "none", sm: 'none', md: 'flex' } }}>
                            <Box mr={2}>
                                {walletData?.isAdmin &&
                                    <Link to="/management">
                                        <span className={classes.button}>
                                            Management
                                        </span>
                                    </Link>
                                }
                            </Box>
                            <Box mr={2}>
                                <span className={classes.button} onClick={() => handleClick("followus")}>
                                    Follow Us
                                </span>
                            </Box>
                            <Box mr={2}>
                                <span className={classes.button} onClick={() => handleClick("airdrop")}>
                                    Airdrop
                                </span>
                            </Box>
                            <Box mr={2}>
                                <span className={classes.button} onClick={() => handleClick("bytoken")}>
                                    Buy
                                </span>
                            </Box>
                            <Box mr={2}>
                                <span className={classes.button} onClick={() => handleClick("invite")}>
                                    Invite
                                </span>
                            </Box>
                            <Box mr={2}>
                                <span className={classes.button} onClick={() => window.open(`https://bscscan.com/token/${tokenAddress}`)}>
                                    Blockchain explorer
                                </span>
                            </Box>
                            <Box mr={2}>
                                <span className={classes.button} onClick={() => window.open(`https://bscscan.com/token/${tokenAddress}`)}>
                                    contract
                                </span>
                            </Box>
                            <Box mr={2}>
                                <span className={classes.button} onClick={() => window.open(`http://bocoin.info/wp-content/uploads/2022/04/BOC-roadmap-2.pdf`)}>
                                    Roadmap
                                </span>
                            </Box>
                            <Box mr={2}>
                                <span className={classes.button} onClick={() => window.open(`http://bocoin.info/wp-content/uploads/2022/05/BOC-whitepaper.pdf`)}>
                                    whitepaper
                                </span>
                            </Box>
                        </Box>
                        <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
                            <IconButton
                                icon={<MenuRoundedIcon />}
                                onClick={handleMobileMenuOpen}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', ml: 2 }}>
                            {walletData?.account ? (
                                <Box display={"flex"} alignItems="center" justifyContent={"centre"}>
                                    <Tooltip title={walletData?.account}>
                                        <StyledBadge
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            variant="dot"
                                        >
                                            <Typography
                                                fontWeight={"700"}
                                                padding={theme.spacing(1)}
                                                border="1px solid"
                                                borderRadius={theme.spacing(1)}
                                            >
                                                {walletData?.account?.substring(0, 8)}
                                            </Typography>
                                        </StyledBadge>
                                    </Tooltip>
                                    <Tooltip title={"Logout"}>
                                        <Box>
                                            <IconButton
                                                onClick={disconnect}
                                                icon={<LogoutRoundedIcon />}
                                            />
                                        </Box>
                                    </Tooltip>
                                </Box>
                            )
                                :
                                <ControlledStartButton
                                    label='Connect Wallet'
                                    onClick={() => setOpen(true)}
                                />
                            }
                        </Box>
                        <IconButton
                            onClick={handleColor}
                            icon={uiStatus === 'dark' ? <ModeNightRoundedIcon /> : <Brightness7Icon />}
                        />
                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
            </Box>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                header={'connect wallet'}
                content={
                    <>
                        <Box
                            sx={{
                                marginLeft: '-1.5rem',
                                display: 'flex',
                                justifyContent: 'space-between',
                                mb: 2
                            }}
                        >
                            <Box>
                                <IconButton
                                    onClick={() => connect("injected")}
                                    icon={<MetaMaskIcon style={{
                                        width: '2em', fontSize: 44,
                                    }} />}
                                />
                            </Box>
                            <Box>
                                <IconButton
                                    onClick={() => connect("walletconnect")}
                                    icon={<WalletConnectIcon style={{
                                        width: '2em', fontSize: 44
                                    }} />}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                marginLeft: '-1.5rem',
                                display: 'flex',
                                justifyContent: 'space-between',
                                mb: 2
                            }}
                        >
                            <Box>
                                <IconButton
                                    onClick={() => connect("walletconnect")}
                                    icon={<TrustWalletIcon style={{
                                        width: '2em', fontSize: 44
                                    }} />}
                                />
                            </Box>
                                <Box display={'flex'} alignItems={'center'}>
                                    <IconButton
                                    onClick={() => connect("walletconnect")}
                                    icon={<img style={{
                                        marginLeft: theme.spacing(3),
                                        cursor: "pointer"
                                    }} src={"/images/safepal.svg"} alt={"safepal"} width={45} height={46} />}
                                    />
                                </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                marginLeft: '1.5rem',
                                justifyContent: 'space-around',
                                alignItems: "center",
                            }}
                            >
                            <Box display={'flex'} alignItems={'center'}>
                                <IconButton
                                    // onClick={() => connect(ledger, "ledger")}
                                    icon={<img
                                        src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAnBJREFUeNrsmj9IHEEUh2fX3Gn8g2I0xBRnTsQmjb2lYiUSglVIlwS0sJAYCIiNtYqVYGVEGxubxMIqKmgiiIVNiI1YSLpE8Zp43E5+LzsD63F3OyN7d3vDPPg4786ZnW/29s3szjicc5YXPeAlGAK9oF587ojXj2CDFY4U2AEtgLPyhAcuwQHYAt/ufEtCAd6Cn7x0jOeVCZIGGV65uAKLoF22wQ24zYAV0KfQQ8WCzkqWVS5awRRYAx30gRR6A+YC72stRsA8XR4k0C3OTq3KyHhFYiQxBtKs9iMB3pHQMDMnBlyRmk2JJhJKGiTEXWZYuPfIbm7chR5olqmLsxDJvKeLSWHu5Qj24y60Ydo1xKyQFbJCViiY5Z6K22yVtE3xB1zFWWgT9IOcghCd0VmwFGehNtCsUaYh7tdQTrOMZ5OCFbJCVsgKWaEIhZyI63Sq6UNCCc1CiRgLeQ7n/AXzn9x7Cg2lDjgCp0X+pxGMiqlUpWcU/zvSKbDgZZOCFbJCVsisZwrPwEPFNEsd8KvEM4WEqM/V6NBS9dFST0q0UyUdu5S2d/HHc41O+AA+FfmODr6neUs/zfxV7EJBi3FfwCPVgZXMO8XAGsUzBVqZeCwGWNVIhvyCnjB/+V75lOvuKwjbp3AbcX067fPKMTmtepbLVrkNUc69HBK6MOgEZUno0CChHyT0mfnbtUyIdRI6A8tVvGONKil9B6tyRF9gtb3Weg4mwW8p9BdMMH+L1k2Fb7F5yLHCjveV+Tswj+VILCMjpjXb4DUYBF1ifsYDFavM+XRuv90Q2ZyoT7YhJ+Z+J8xfCqJtmteywD8BBgDZ8yd0Dz2RkwAAAABJRU5ErkJggg=='
                                        width={33}
                                        height={39}
                                        alt="ledger"
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        />}
                                        />
                            </Box>
                                    <Box>
                                            <IconButton
                                            onClick={() => connect("binancechainwallet")}
                                            icon={<BinanceChainIcon style={{
                                                width: '2em', fontSize: 44
                                            }} />}
                                            />
                                    </Box>
                        </Box>
                    </>
                }
            />
        </>
    );
}



