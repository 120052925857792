import { CssBaseline, Divider, GlobalStyles, useTheme } from '@mui/material';
import React from 'react'
import Footer from '../layout/footer/footer';
import AppBar from '../layout/appBar/appBar';
import { selectedColor } from '../../app/uiSlice';
import { useAppSelector } from '../../app/hooks';


interface Props {
  children: any
}

export default function Layout(props: Props) {

  const uiStatus = useAppSelector(selectedColor);
  const theme = useTheme()

  return (
    <React.Fragment>
      <div>
        <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
        <CssBaseline />
        <AppBar />
        <main style={{ backgroundColor: uiStatus === 'dark' ? theme.palette.primary.main : theme.palette.primary.light }}>
          {props.children}
        </main>
        <Footer />
      </div>
    </React.Fragment>
  )
}
