import * as React from 'react';
import { IconButton as MuiIconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from '../layout/theme';

const useStyles = makeStyles({
    root: {
        color: 'white !important',
        '&:hover': {
            background: "none",
            color: `${theme.palette.secondary.light} !important`,
        },
    },
});

interface Props {
    icon: any;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export default function IconButton(props: Props) {
    const classes = useStyles();
    return (
        <MuiIconButton className={classes.root} onClick={props.onClick}>
            {props.icon}
        </MuiIconButton>
    );
}