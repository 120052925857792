import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Home from './components/layout/home';
import { ThemeProvider } from '@mui/material/styles';
import theme from './components/layout/theme';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { Web3ReactProvider } from '@web3-react/core';
import { ExternalProvider, JsonRpcFetchFunc, Web3Provider } from "@ethersproject/providers"
import { BlockchainDispatchContextProvider, BlockchainStateContextProvider } from './context/BlockchainContext';
import blockchainReducer, { initialState } from './context/store/blockchainReducer'

function getLibrary(provider: ExternalProvider | JsonRpcFetchFunc) {
  const library = new Web3Provider(provider);
  return library;
}

export default function App() {
  const [state, dispatch] = React.useReducer(blockchainReducer, initialState);
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Provider store={store}>
        <BlockchainDispatchContextProvider value={dispatch}>
          <BlockchainStateContextProvider value={state}>
            <BrowserRouter>
              <ThemeProvider theme={theme}>
                <Home />
              </ThemeProvider>
            </BrowserRouter>
          </BlockchainStateContextProvider>
        </BlockchainDispatchContextProvider>
      </Provider>
    </Web3ReactProvider >
  );
}
