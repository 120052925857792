import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import theme from '../../theme';
import { selectedColor } from '../../../../app/uiSlice';
import { useAppSelector } from '../../../../app/hooks';

const useStyles = makeStyles({
    root: {
        [theme.breakpoints.down("sm")]: {
            paddingTop: '3rem'
        },
        justifyContent: "center",
        minHeight: "35em",
        overflow: "hidden",
        position: "relative",
        textAlign: "center",
        height: "auto",
        alignItems: "center"
    },
    "@keyframes slidein": {
        "from": {
            marginLeft: "1%",
            width: "130%",
        },
        "to": {
            marginleft: "0%",
            width: "80%",
        }
    },
    image: {
        animationDuration: "3s",
        animationName: "$slidein",
        transition: 'transform .4s',
        "&:hover": {
            transform: 'scale(1.03)',
        },
    },
});


function First() {
    const classes = useStyles();
    const uiStatus = useAppSelector(selectedColor);

    return (
        <Container maxWidth="xl">
            <Grid container spacing={2} className={classes.root}>
                <Grid item sm={6} xs={12}>
                    <Typography
                        component="h4"
                        variant="h4"
                        align="left"
                        color={uiStatus === "dark" ? "white" : "black"}
                        gutterBottom
                    >
                        The payment service provider & first Cryptocurrency which do the economic activities in the world with support and safe structure.
                    </Typography>
                    <Typography variant="subtitle1" align="left"
                        color={uiStatus === "dark" ? "white" : "black"}
                        component="p">
                        We are here to be a solution and a corner of justice to approach great safe transactions and non-inflationary, contains value added without intensive fluctuation.
                    </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <img
                        className={classes.image}
                        src={"/images/Manageـmoneyـamico.png"}
                        alt="Asset"
                        height="80%"
                        width="80%"
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export default First;