import React from 'react'
import Layout from '../common/layout'
import RoutesLayout from './routes'

export default function Home() {
    return (
        <Layout>
            <RoutesLayout />
        </Layout>
    )
}
