import { ethers } from "ethers";


export const toEther = (props: any) => {
  return ethers.utils.formatEther(props);
}

export const toWei = (props: any) => {
  return ethers.utils.parseEther(props);
}
