import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface UiState {
  status: 'dark' | 'light';
  account: undefined,
}

const initialState: UiState = {
  status: 'dark',
  account: undefined,
};

export const counterSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    changeColor: (state, actions) => {
      if (state.status === 'dark') state.status = 'light';
      else state.status = 'dark';
    },
  },
});

export const { changeColor } = counterSlice.actions;

export const changedColor = () => ({
  type: changeColor.type,
});

export const selectedColor = (state: RootState) => state.ui.status;

export default counterSlice.reducer;
