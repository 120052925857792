import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Container } from '@mui/material';
import { selectedColor } from '../../../../app/uiSlice';
import { useAppSelector } from '../../../../app/hooks';

const useStyles = makeStyles({
    root: {
        justifyContent: "center",
        minHeight: "25em",
        overflow: "hidden",
        position: "relative",
        textAlign: "center",
        alignItems: "center",
    },
    image: {
        transition: 'transform .4s',
        "&:hover": {
            transform: 'scale(1.03)',
        },
    },
});

function Thirth() {
    const classes = useStyles();
    const uiStatus = useAppSelector(selectedColor);

    return (
        <Container maxWidth="xl">
            <Grid container spacing={5} className={classes.root}>
                <Grid item sm={6} xs={12}>
                    <Typography
                        component="h4"
                        variant="h4"
                        align="left"
                        color={uiStatus === "dark" ? "white" : "black"}
                        gutterBottom
                    >
                        What is the backing of BO?
                    </Typography>
                    <Typography variant="subtitle1" align="left"
                        color={uiStatus === "dark" ? "white" : "black"}
                        component="p">
                        Purchase precious metals and shares of world-renowned companies and keep them in reputable and approved funds.
                    </Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <img
                        className={classes.image}
                        src={"/images/Business_analytics_amico.png"}
                        alt="Asset"
                        height="70%"
                        width="70%"
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export default Thirth;