import * as React from 'react';
import { makeStyles } from '@mui/styles';
import theme from '../layout/theme';
import { Button } from '@mui/material';

const useStyles = makeStyles({
    button: {
        "&:hover": {
            color: `${theme.palette.secondary.main} !important`,
        },
    },
});

interface Props {
    title: String;
    startIcon?: any;
    onClick?: any;
    background?: string;
}

export default function FormButton(props: Props) {
    const classes = useStyles();

    return (
        <Button
            className={classes.button}
            style={{
                background: props.background,
                margin: theme.spacing(1),
                maxWidth: '10rem',
                minWidth: '10rem',
                boxShadow: '0 3px 5px 2px rgb(223 209 212 / 30%)',
                color: "white"
            }}
            onClick={() => props.onClick()}
            startIcon={props.startIcon}
        >
            {props.title}
        </Button>
    );
}