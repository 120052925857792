import { createContext } from "react";

export const BlockchainDispatchContext = createContext();
export const BlockchainStateContext = createContext();

export const BlockchainDispatchContextProvider = ({ value, children }) => (
  <BlockchainDispatchContext.Provider value={value}>
    {children}
  </BlockchainDispatchContext.Provider>
);

export const BlockchainStateContextProvider = ({ value, children }) => (
  <BlockchainStateContext.Provider value={value}>
    {children}
  </BlockchainStateContext.Provider>
);
