import { Alert, AlertTitle, Box, Card, CardContent, Container, Grid, Snackbar, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Button from '../common/button'
import Input from '../common/input'
import { makeStyles } from '@mui/styles';
import Slide, { SlideProps } from '@mui/material/Slide';
import InsertInvitationRoundedIcon from '@mui/icons-material/InsertInvitationRounded';
import theme from '../layout/theme';
import { BlockchainStateContext } from '../../context/BlockchainContext';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { TransitionProps } from '@mui/material/transitions';
import Grow, { GrowProps } from '@mui/material/Grow';
import Fade from '@mui/material/Fade';
import { selectedColor } from '../../app/uiSlice';
import { useAppSelector } from '../../app/hooks';

const useStyles = makeStyles({
    root: {
        justifyContent: "center",
        minHeight: "30em",
        overflow: "hidden",
        position: "relative",
        textAlign: "center",
        alignItems: "center",
        height: "auto",
        borderColor: "white",
        borderRadius: 5,
    },
    snackBar: {
        "& .css-1drnr38-MuiPaper-root-MuiSnackbarContent-root": {
            backgroundColor: theme.palette.primary.light
        }
    }
});

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

export default function Invitation() {
    const classes = useStyles();
    const uiStatus = useAppSelector(selectedColor);
    const { account } = React.useContext(BlockchainStateContext);
    const urlPath = window.location.host + "/?ref=" + account;

    const [alertt , setAlertt] = useState<any>(false)
    const [state, setState] = React.useState<{
        open: boolean;
        Transition: React.ComponentType<
            TransitionProps & {
                children: React.ReactElement<any, any>;
            }
        >;
    }>({
        open: false,
        Transition: Fade,
    });

    const handleClose = () => {
        setState({
            ...state,
            open: false,
        });
    };

    const handleClick =
        (
            Transition: React.ComponentType<
                TransitionProps & {
                    children: React.ReactElement<any, any>;
                }
            >,
        ) =>
            () => {
                setState({
                    open: true,
                    Transition,
                });
            };


    return (
        <Container maxWidth={'xl'}>
            <Card sx={{
                backgroundColor: "transparent"
            }}>
                <CardContent sx={{
                    marginBottom: theme.spacing(3),
                    border: "1px solid",
                    borderColor: uiStatus === "dark" ? "white" : "black",
                    borderRadius: theme.spacing(1)
                }}>
                    <Grid container spacing={5} className={classes.root} id={'invite'} sx={{ mt: 8, }}>
                        <Grid item sm={6} xs={12} alignItems={'center'} justifyContent='center' display={'flex'}>
                            <Card sx={{ width: '100%', background: "none", boxShadow: "none" }}>
                                <CardContent>
                                    <Typography component="h4"
                                        variant="h4"
                                        align="left"
                                        color={uiStatus === "dark" ? "white" : "black"}
                                        gutterBottom>
                                        Generate Your Referral Link Now!
                                    </Typography>
                                    <Input
                                        disabled={true}
                                        value={(account === "" || account === null) ? "" : urlPath}
                                        type='text' />
                                    <Typography
                                        color={uiStatus === "dark" ? "white" : "black"}
                                        display={'flex'} variant="body2">
                                        Invite people to get reward
                                    </Typography>
                                </CardContent>
                                <Box display={'flex'} justifyContent={'end'} padding={2}>
                                    {(account === "" || account === null)
                                        ?
                                        <Button
                                            startIcon={<InsertInvitationRoundedIcon />}
                                            title='copy invitation link'
                                            
                                        />
                                        :
                                        <CopyToClipboard
                                            text={urlPath}
                                            onCopy={handleClick(SlideTransition)}
                                        >
                                            <Button
                                                // startIcon={<InsertInvitationRoundedIcon color="primary" />}
                                                title='copy invitation link'
                                                onClick={ ()=> setAlertt(true)}
                                            />
                                        </CopyToClipboard>
                                    }
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Typography
                                component="h4"
                                variant="h4"
                                align="left"
                                color={uiStatus === "dark" ? "white" : "black"}
                                gutterBottom
                            >
                                Invite your friends
                            </Typography>
                            <Typography variant="subtitle1" align="left"
                                color={uiStatus === "dark" ? "white" : "black"}
                                component="p">
                               You can invite your friends with your invitation link. For everyone who buys tokens and participates in Airdrop, you will receive 10% AirDrop reward and the amount of their purchase.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            {/* <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                    This is a success alert — <strong>check it out!</strong>
            </Alert> */}
            </Card>
            {/* <Snackbar
                className={classes.snackBar}
                open={state.open}
                onClose={handleClose}
                TransitionComponent={state.Transition}
                message="Copied to clipboard"
                key={state.Transition.name}
                // classes="blue"
            /> */}
            <Snackbar
                open={alertt}
                autoHideDuration={1000}
                onClose={handleClose}
                message="Referral link copied"
                // action={() => setAlertt(false)}
            />
        </Container>
    )
}
