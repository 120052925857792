import { Container, Typography } from '@mui/material'
import { ethers } from 'ethers'
import React, { useEffect, useState } from 'react'
import { BlockchainDispatchContext, BlockchainStateContext } from '../../context/BlockchainContext'
import { daiAddress, icoAbi, icoAddress, tokenAbi, tokenAddress, usdcAddress, usdtAddress } from '../../wallet/abi'
import { toEther, toWei } from '../../wallet/utils'
import Card from '../common/card'
import Input from '../common/input'
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import Button from '../common/button'
import Box from '@mui/material/Box';
import PlayCircleFilledWhiteRoundedIcon from '@mui/icons-material/PlayCircleFilledWhiteRounded';
import CountDown from '../common/countDown';
import * as actions from "../../context/store/actionTypes";
import Dialog from '../common/dialog'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { selectedColor } from '../../app/uiSlice'
import { useAppSelector } from '../../app/hooks'

export default function Management() {

    const [tokenAmount, setTokenAmount] = useState<number>(0)
    const [tokenBalance, setTokenBalance] = useState<any>()
    const [ethAmount, setEthAmount] = useState<number>(0)
    const [ethBalance, setEthBalance] = useState<any>()
    const [usdtAmount, setUsdtAmount] = useState<number>(0)
    const [usdtBalance, setUsdtBalance] = useState<any>()
    const [daiAmount, setdaiAmount] = useState<number>(0)
    const [daiBalance, setdaiBalance] = useState<any>()
    const [usdcAmount, setUsdcAmount] = useState<number>(0)
    const [usdcBalance, setUsdcBalance] = useState<any>()
    const [status, setStatus] = useState<any>()
    const communicatorDispatch = React.useContext(BlockchainDispatchContext);
    const [bokoWithdrawLoading, setBokoWithdrawLoading] = useState<boolean>(false)
    const [bokoDepositLoading, setBokoDepositLoading] = useState<boolean>(false)
    const [startLoading, setstartLoading] = useState<boolean>(false)
    const [endLoading, setEndLoadin] = useState<boolean>(false)
    const [bnbWithdrawLoading, setBnbWithdrawLoading] = useState<boolean>(false)
    const [usdtWithdrawLoading, setUsdtWithdrawLoading] = useState<boolean>(false)
    const [daiWithdrawLoading, setDaiWithdrawLoading] = useState<boolean>(false)
    const [usdcWithdrawLoading, setUsdcWithdrawLoading] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const [text, setText] = useState<string>("")
    const { library, account, deadLineDate, isAdmin } = React.useContext(BlockchainStateContext);
    const [airdropHistory, setAirdropHistory] = useState<any>()
    const [icoHistory, setIcoHistory] = useState<any>()
    const uiStatus = useAppSelector(selectedColor);
    const [showDialog, setShowDialog] = React.useState<boolean>(false);
    const [functionName, setFucntionName] = useState<any>()

    useEffect(() => {
        if (library) {
            showToken()
            showEther()
            showUsdt()
            showDai()
            showusdc()
            showStatus()
            showDeadline()
            showHistory()
        }
    }, [library])

    function numberWithCommas(x : any) {
        const y = x - parseInt(x)
        if(y > 0.0001){
            const v = (typeof x === 'string' ? x : x.toString()).split('.');
            // if (n <= 0) return v[0];
            let f = v[1] || '';
            if (f.length > 4) return `${v[0]}.${f.substr(0,4)}`;
            while (f.length < 4) f += '0';
            return `${v[0]}.${f}`
            // let y = Number(x).toFixed(4);
            //  return Number(x).original.value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]
            // return Number(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '');
            // return Number(y).toLocaleString();
    }else{
        return parseInt(x).toString()
    }
      }

    const showHistory = async () =>{
        if(library){
            const icoContract = new ethers.Contract(icoAddress, icoAbi, library)
            const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, library)
            const airdropHistory = await icoContract.airdropHistory()
            setAirdropHistory(toEther(airdropHistory))
            const icoHistory = await icoContract.icoHistory()
            setIcoHistory(toEther(icoHistory))

        }
    }

    const startAirdrop = async () => {
        if (library) {
            setstartLoading(true)
            const icoContract = new ethers.Contract(icoAddress, icoAbi, library)
            const signer = library.getSigner()
            const res = await icoContract.connect(signer).start()
            const receipt = await res.wait()
            
            if (receipt.status == 1) {
                setstartLoading(false)
                setFucntionName("Start Airdrop")
                setShowDialog(true)
                showDeadline()
                showStatus()

            }else{
                setstartLoading(false)

            }
        }
    }

        

    const endAirdrop = async () => {
        if (library) {
            setEndLoadin(true)
            const icoContract = new ethers.Contract(icoAddress, icoAbi, library)
            const signer = library.getSigner()
            const res = await icoContract.connect(signer).end()
            const receipt = await res.wait()
            
            if (receipt.status == 1) {
                setEndLoadin(false)
                setFucntionName("Start Airdrop")
                setShowDialog(true)
                showDeadline()
                showStatus()

            }else{
                setEndLoadin(false)

            }

        }
    }

    const addToken = async (amount: number) => {
        if (library) {
            setBokoDepositLoading(true)
            const icoContract = new ethers.Contract(icoAddress, icoAbi, library)
            const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, library)
            const signer = library.getSigner()
            await tokenContract.connect(signer).approve(icoAddress, toWei(amount))
            const res = await icoContract.connect(signer).addToken(toWei(amount), { gasLimit: 400000 })
            const receipt = await res.wait()
            
                    if (receipt.status == 1) {
                        setBokoDepositLoading(false)
                        setFucntionName("Deposit Boc")
                        setShowDialog(true)
                        showToken()
        
                    }else{
                        setBokoDepositLoading(false)
        
                    }    
                }
            }



    const showToken = async () => {
        if (library) {
            const tokenContract = new ethers.Contract(tokenAddress, tokenAbi, library)
            const tokenBalance = await tokenContract.balanceOf(icoAddress)
            setTokenBalance(toEther(tokenBalance))
        }
    }

    const removeToken = async (amount: number) => {
        if (library) {
                setBokoWithdrawLoading(true)
                const icoContract = new ethers.Contract(icoAddress, icoAbi, library)
                const signer = library.getSigner()
                const res = await icoContract.connect(signer).removeToken(toWei(amount), { gasLimit: 400000 })      
                const receipt = await res.wait()
                
                if (receipt.status == 1) {
                    setBokoWithdrawLoading(false)
                    setFucntionName("Withdraw Boc")
                    setShowDialog(true)
                    showToken()

                }else{
                    setBokoWithdrawLoading(false)

                }        
            }
        }
                    



    const showEther = async () => {
        if (library) {
            const icoContract = new ethers.Contract(icoAddress, icoAbi, library)
            const ethBalance = await icoContract.ethBalance()
            setEthBalance(toEther(ethBalance))
        }
    }


    const removeEther = async (amount: number) => {
        if (library) {
            setBnbWithdrawLoading(true)
            const icoContract = new ethers.Contract(icoAddress, icoAbi, library)
            const signer = library.getSigner()
            const res = await icoContract.connect(signer).removeEther(toWei(amount), { gasLimit: 400000 })
            const receipt = await res.wait()
        
            if (receipt.status == 1) {
                setBnbWithdrawLoading(false)
                setFucntionName("Withdraw BNB")
                setShowDialog(true)
                showEther()

            }else{
                setBnbWithdrawLoading(false)

            }          
        }
    }


    const showUsdt = async () => {
        if (library) {
            const tokenContract = new ethers.Contract(usdtAddress, tokenAbi, library)
            const tokenBalance = await tokenContract.balanceOf(icoAddress)
            setUsdtBalance(toEther(tokenBalance))
        }
    }

    const removeUsdt = async (amount: number) => {
        if (library) {
            setUsdtWithdrawLoading(true)
            const icoContract = new ethers.Contract(icoAddress, icoAbi, library)
            const signer = library.getSigner()
            const res = await icoContract.connect(signer).removeStableCoin(usdtAddress, toWei(amount), { gasLimit: 400000 })
            const receipt = await res.wait()
    
            if (receipt.status == 1) {
                setUsdtWithdrawLoading(false)
                setFucntionName("Withdraw USDT")
                setShowDialog(true)
                showUsdt()

            }else{
                setUsdtWithdrawLoading(false)
            }     

        }
    }


    const showDai = async () => {
        if (library) {
            const tokenContract = new ethers.Contract(daiAddress, tokenAbi, library)
            const tokenBalance = await tokenContract.balanceOf(icoAddress)
            setdaiBalance(toEther(tokenBalance))
        }
    }

    const removeDai = async (amount: number) => {
        if (library) {
            setDaiWithdrawLoading(true)
            const icoContract = new ethers.Contract(icoAddress, icoAbi, library)
            const signer = library.getSigner()
            const res = await icoContract.connect(signer).removeStableCoin(daiAddress, toWei(amount), { gasLimit: 400000 })
            const receipt = await res.wait()

                if (receipt.status == 1) {
                    setDaiWithdrawLoading(false)
                    setFucntionName("Withdraw DAI")
                    setShowDialog(true)
                    showDai()
    
                }else{
                    setDaiWithdrawLoading(false)
                }     

        }
    }

    const showusdc = async () => {
        if (library) {
            const tokenContract = new ethers.Contract(usdcAddress, tokenAbi, library)
            const tokenBalance = await tokenContract.balanceOf(icoAddress)
            setUsdcBalance(toEther(tokenBalance))
        }
    }

    const removeUsdc = async (amount: number) => {
        if (library) {
            setUsdcWithdrawLoading(true)
            const icoContract = new ethers.Contract(icoAddress, icoAbi, library)
            const signer = library.getSigner()
            const res = await icoContract.connect(signer).removeStableCoin(usdcAddress, toWei(amount), { gasLimit: 400000 })
             const receipt = await res.wait()

                if (receipt.status == 1) {
                    setUsdcWithdrawLoading(false)
                    setFucntionName("Withdraw USDC")
                    setShowDialog(true)
                    showusdc()
                }else{
                    setUsdcWithdrawLoading(false)
                }     
        

        }
    }

    const showStatus = async () => {
        if (library) {
            const icoContract = new ethers.Contract(icoAddress, icoAbi, library)
            const data = await icoContract.isStarted()
            setStatus(data)
        }
    }

    const showDeadline = async () => {
        if (library) {
            const icoContract = new ethers.Contract(icoAddress, icoAbi, library)
            const deadline = await icoContract.deadline()
            communicatorDispatch({
                type: actions.DEAD_LINE,
                payload: parseInt(deadline),
            });
        }
    }

    if (!isAdmin) {
        return (
            <Container disableGutters maxWidth="lg" sx={{
                pt: 14, pb: 6,
                height: "calc(100vh - 64px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Typography
                    variant="h4"
                    align="center"
                    color={uiStatus === "dark" ? "white" : "black"}
                    gutterBottom
                    component="p"
                >Your are not admin.</Typography>
            </Container>
        )
    } else
        return (
            <Container disableGutters maxWidth="lg" sx={{ pt: 14, pb: 6 }}>
                <div style={{ width: '100%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: "center",
                            p: 1,
                            m: 1,
                            borderRadius: 1,
                            flexWrap: "wrap"
                        }}
                    >
                        
                        <Card
                            header={"BOC TOKEN"}
                            title={numberWithCommas(tokenBalance)}
                            content={
                                <Input
                                    value={tokenAmount}
                                    onChange={(e: { target: { value: number } }) => {
                                        if (e.target.value < 0) return
                                        setTokenAmount(e.target.value)
                                    }}
                                    type='number' />
                            }
                            actions={
                                <>
                                    <Button
                                        loading={bokoWithdrawLoading}
                                        title='withdraw' startIcon={<ShoppingCartRoundedIcon />} onClick={() => removeToken(tokenAmount)} />
                                    <Button
                                        loading={bokoDepositLoading}
                                        title='deposit' startIcon={<ShoppingCartRoundedIcon />} onClick={() => addToken(tokenAmount)} />
                                </>
                            }
                        />
                        <Card
                            header={"START AND END"}
                            mt={.8}
                            title={status ? "AIRDROP IS ON" : "AIRDROP IS OFF"}
                            content={deadLineDate &&
                                <CountDown
                                    startTime={deadLineDate}
                                />
                            }
                            actions={
                                <>
                                    <Button
                                        loading={startLoading}
                                        title='START' startIcon={<PlayCircleFilledWhiteRoundedIcon />} onClick={() => startAirdrop()} />
                                    <Button
                                        loading={endLoading}
                                        title='END' startIcon={<PlayCircleFilledWhiteRoundedIcon />} onClick={() => endAirdrop()} />
                                </>
                            }
                        />
                        <Card
                            header={"BNB"}
                            title={numberWithCommas(ethBalance)}
                            content={
                                <>
                                    <Input
                                        value={ethAmount}
                                        onChange={(e: { target: { value: any } }) => {
                                            if (e.target.value < 0) return
                                            setEthAmount(e.target.value)
                                        }}
                                        type='number' />
                                </>
                            }
                            actions={
                                <Button
                                    loading={bnbWithdrawLoading}
                                    title='withdraw' startIcon={<ShoppingCartRoundedIcon />} onClick={() => removeEther(ethAmount)} />
                            }
                        />
                    </Box>
                </div>
                <div style={{ width: '100%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: "center",
                            p: 1,
                            m: 1,
                            borderRadius: 1,
                            flexWrap: "wrap"
                        }}
                    >
                        <Card
                            header={"USDT"}
                            title={numberWithCommas(usdtBalance)}
                            content={
                                <>
                                    <Input
                                        value={usdtAmount}
                                        onChange={(e: { target: { value: any } }) => {
                                            if (e.target.value < 0) return
                                            setUsdtAmount(e.target.value)
                                        }}
                                        type='number' />
                                </>
                            }
                            actions={
                                <Button
                                    loading={usdtWithdrawLoading}
                                    title='withdraw' startIcon={<ShoppingCartRoundedIcon />} onClick={() => removeUsdt(usdtAmount)} />
                            }
                        />
                        <Card
                            header={"DAI"}
                            title={numberWithCommas(daiBalance)}
                            content={
                                <>
                                    <Input
                                        value={daiAmount}
                                        onChange={(e: { target: { value: any } }) => {
                                            if (e.target.value < 0) return
                                            setdaiAmount(e.target.value)
                                        }}
                                        type='number' />
                                </>
                            }
                            actions={
                                <Button
                                    loading={daiWithdrawLoading}
                                    title='withdraw' startIcon={<ShoppingCartRoundedIcon />} onClick={() => removeDai(daiAmount)} />
                            }
                        />
                        <Card
                            header={"USDC"}
                            title={numberWithCommas(usdcBalance)}
                            content={
                                <>
                                    <Input
                                        value={usdcAmount}
                                        onChange={(e: { target: { value: any } }) => {
                                            if (e.target.value < 0) return
                                            setUsdcAmount(e.target.value)
                                        }
                                        }
                                        type='number' />
                                </>
                            }
                            actions={
                                <Button
                                    loading={usdcWithdrawLoading}
                                    title='withdraw' startIcon={<ShoppingCartRoundedIcon />} onClick={() => removeUsdc(usdcAmount)} />
                            }
                        />
                    </Box>
                <>
                <Dialog
                open={showDialog}
                onClose={() => setShowDialog(false)}
                header={functionName}
                content={
                    <Typography
                        variant="h4"
                        align="center"
                        color={uiStatus === "dark" ? "white" : "black"}
                        gutterBottom
                        component="p"
                    >
                        {"Successfull !!!"}
                    </Typography>
                }
                actions={
                    <Button
                        onClick={() => setShowDialog(false)}
                        title='ok'
                        startIcon={<CheckRoundedIcon />}
                    />
                }
            />
            </>
                    <Card
                            // header={"Detail"}
                            // title={tokenBalance}
                            content={
                                <>
                                <h2>Airdrop Balance      : {numberWithCommas(30000000-airdropHistory)}</h2>
                                <h2>Airdrop Spend tokens : {numberWithCommas(airdropHistory)}</h2><br/>
                                <h2>Ico Balance          : {numberWithCommas(1000000000-icoHistory)}</h2>
                                <h2>Sold tokens in Ico   : {numberWithCommas(icoHistory)}</h2>
                                </>
                            //     <Input
                            //         value={tokenAmount}
                            //         onChange={(e: { target: { value: number } }) => {
                            //             if (e.target.value < 0) return
                            //             setTokenAmount(e.target.value)
                            //         }}
                            //         type='number' />
                            // }
                            // actions={
                            //     <>
                            //         <Button
                            //             loading={bokoWithdrawLoading}
                            //             title='withdraw' startIcon={<ShoppingCartRoundedIcon />} onClick={() => removeToken(tokenAmount)} />
                            //         <Button
                            //             loading={bokoDepositLoading}
                            //             title='deposit' startIcon={<ShoppingCartRoundedIcon />} onClick={() => addToken(tokenAmount)} />
                            //     </>
                            }
                        />
                </div>
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    header={'Token Status'}
                    content={
                        <Typography
                            variant="h4"
                            align="center"
                            color={uiStatus === "dark" ? "white" : "black"}
                            gutterBottom
                            component="p"
                        >
                            {text}
                        </Typography>
                    }
                    actions={
                        <Button
                            onClick={() => setOpen(false)}
                            title='ok'
                            startIcon={<CheckRoundedIcon />}
                        />
                    }
                />
            </Container>
        )
}
