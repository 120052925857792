// export const tokenAddress = "0xf9689420654011B94189243140C417790Af59651"; //test
export const tokenAddress = "0x80e7dc4e726E052b0dB04ec8b506596458809c11"; //main

// export const icoAddress = "0x3d910B21A8A01642d4a14bDc05e07BB904727813"; //test
export const icoAddress = "0x1016EF32Bf89baCa783bfd70087238566acb9492"; //main-v3
// export const icoAddress = "0x1E29292dC595e0D26C604715A3ce6443f35EB190"; //main-v2
// export const icoAddress = "0x2e98EBfaBe98a2A90dD498C1ec729713e22cC39F"; //main-v1

// export const usdtAddress = "0x425c7E0DcFf90D9d61b1915363691a1B890831fC"; //test
export const usdtAddress = "0x55d398326f99059ff775485246999027b3197955"; //main

// export const daiAddress = "0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735"; //test
export const daiAddress = "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3"; //main

// export const usdcAddress = "0x0006DC3FA5754aD0ba7b373f84a3d1eA5C155EDD"; //test
export const usdcAddress = "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d"; //main

export const routerAddress = "0x10ED43C718714eb63d5aA57B78B54704E256024E" // pancake router
export const factoryAddress = "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73" // pancake factory


// export const wethAddress = "0xc778417E063141139Fce010982780140Aa0cD5Ab" //wbnb test
export const wethAddress = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" //wbnb main


export const icoAbi = [
  {
    "inputs": [
      {
        "internalType": "contract ERC20",
        "name": "_token",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_usdt",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_dai",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_usdc",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_priceFeed",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_router",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_factory",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_weth",
        "type": "address"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "stateMutability": "payable",
    "type": "fallback"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "addToken",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_refer",
        "type": "address"
      }
    ],
    "name": "airdrop",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "airdropAmount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "airdropHistory",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_refer",
        "type": "address"
      }
    ],
    "name": "buy",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "tokenAddress",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_tokenAmount",
        "type": "uint256"
      }
    ],
    "name": "buyWithAllTokens",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_tokenIn",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_tokenOut",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenAmount",
        "type": "uint256"
      }
    ],
    "name": "buyWithAllTokens2",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_refer",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "stableCoin",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "buyWithToken",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_newPrice",
        "type": "uint256"
      }
    ],
    "name": "changePrice",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_newFeed",
        "type": "address"
      }
    ],
    "name": "changePriceFeed",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "dai",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "deadline",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "end",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "ethBalance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "factory",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_tokenIn",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_tokenOut",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "tokenAmount",
        "type": "uint256"
      }
    ],
    "name": "getAmountsOut",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getLatestPrice",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address[]",
        "name": "path",
        "type": "address[]"
      }
    ],
    "name": "giveAirdroptoAll",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "icoBalance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "icoHistory",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "name": "isGotAirdrop",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "isStarted",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "maxBalance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "removeEther",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "stableCoin",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "removeStableCoin",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "removeToken",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "router",
    "outputs": [
      {
        "internalType": "contract IUniswapV2Router02",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "start",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "startTime",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "token",
    "outputs": [
      {
        "internalType": "contract ERC20",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "tokenPrice",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "tokenRate",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalTime",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "usdc",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "usdt",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "weth",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
];

export const tokenAbi = [
  {
    "inputs": [],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Approval",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "Paused",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "from",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "to",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "value",
        "type": "uint256"
      }
    ],
    "name": "Transfer",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": false,
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "Unpaused",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      }
    ],
    "name": "allowance",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "approve",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "name": "blackList",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "burn",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "burnFrom",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "decimals",
    "outputs": [
      {
        "internalType": "uint8",
        "name": "",
        "type": "uint8"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "subtractedValue",
        "type": "uint256"
      }
    ],
    "name": "decreaseAllowance",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "freezeAccount",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "spender",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "addedValue",
        "type": "uint256"
      }
    ],
    "name": "increaseAllowance",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "mint",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "name",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "paused",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "pausing",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "symbol",
    "outputs": [
      {
        "internalType": "string",
        "name": "",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "recipient",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "transfer",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "sender",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "recipient",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "amount",
        "type": "uint256"
      }
    ],
    "name": "transferFrom",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "unPausing",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "account",
        "type": "address"
      }
    ],
    "name": "unfreezeAccount",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
];

export const routerAbi = [
  {
      inputs: [
          {
              internalType: "address",
              name: "_factory",
              type: "address",
          },
          {
              internalType: "address",
              name: "_WETH",
              type: "address",
          },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
  },
  {
      inputs: [],
      name: "WETH",
      outputs: [
          {
              internalType: "address",
              name: "",
              type: "address",
          },
      ],
      stateMutability: "view",
      type: "function",
  },
  {
      inputs: [
          {
              internalType: "address",
              name: "tokenA",
              type: "address",
          },
          {
              internalType: "address",
              name: "tokenB",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "amountADesired",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountBDesired",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountAMin",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountBMin",
              type: "uint256",
          },
          {
              internalType: "address",
              name: "to",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "deadline",
              type: "uint256",
          },
      ],
      name: "addLiquidity",
      outputs: [
          {
              internalType: "uint256",
              name: "amountA",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountB",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "liquidity",
              type: "uint256",
          },
      ],
      stateMutability: "nonpayable",
      type: "function",
  },
  {
      inputs: [
          {
              internalType: "address",
              name: "token",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "amountTokenDesired",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountTokenMin",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountETHMin",
              type: "uint256",
          },
          {
              internalType: "address",
              name: "to",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "deadline",
              type: "uint256",
          },
      ],
      name: "addLiquidityETH",
      outputs: [
          {
              internalType: "uint256",
              name: "amountToken",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountETH",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "liquidity",
              type: "uint256",
          },
      ],
      stateMutability: "payable",
      type: "function",
  },
  {
      inputs: [],
      name: "factory",
      outputs: [
          {
              internalType: "address",
              name: "",
              type: "address",
          },
      ],
      stateMutability: "view",
      type: "function",
  },
  {
      inputs: [
          {
              internalType: "uint256",
              name: "amountOut",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "reserveIn",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "reserveOut",
              type: "uint256",
          },
      ],
      name: "getAmountIn",
      outputs: [
          {
              internalType: "uint256",
              name: "amountIn",
              type: "uint256",
          },
      ],
      stateMutability: "pure",
      type: "function",
  },
  {
      inputs: [
          {
              internalType: "uint256",
              name: "amountIn",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "reserveIn",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "reserveOut",
              type: "uint256",
          },
      ],
      name: "getAmountOut",
      outputs: [
          {
              internalType: "uint256",
              name: "amountOut",
              type: "uint256",
          },
      ],
      stateMutability: "pure",
      type: "function",
  },
  {
      inputs: [
          {
              internalType: "uint256",
              name: "amountOut",
              type: "uint256",
          },
          {
              internalType: "address[]",
              name: "path",
              type: "address[]",
          },
      ],
      name: "getAmountsIn",
      outputs: [
          {
              internalType: "uint256[]",
              name: "amounts",
              type: "uint256[]",
          },
      ],
      stateMutability: "view",
      type: "function",
  },
  {
      inputs: [
          {
              internalType: "uint256",
              name: "amountIn",
              type: "uint256",
          },
          {
              internalType: "address[]",
              name: "path",
              type: "address[]",
          },
      ],
      name: "getAmountsOut",
      outputs: [
          {
              internalType: "uint256[]",
              name: "amounts",
              type: "uint256[]",
          },
      ],
      stateMutability: "view",
      type: "function",
  },
  {
      inputs: [
          {
              internalType: "uint256",
              name: "amountA",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "reserveA",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "reserveB",
              type: "uint256",
          },
      ],
      name: "quote",
      outputs: [
          {
              internalType: "uint256",
              name: "amountB",
              type: "uint256",
          },
      ],
      stateMutability: "pure",
      type: "function",
  },
  {
      inputs: [
          {
              internalType: "address",
              name: "tokenA",
              type: "address",
          },
          {
              internalType: "address",
              name: "tokenB",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "liquidity",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountAMin",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountBMin",
              type: "uint256",
          },
          {
              internalType: "address",
              name: "to",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "deadline",
              type: "uint256",
          },
      ],
      name: "removeLiquidity",
      outputs: [
          {
              internalType: "uint256",
              name: "amountA",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountB",
              type: "uint256",
          },
      ],
      stateMutability: "nonpayable",
      type: "function",
  },
  {
      inputs: [
          {
              internalType: "address",
              name: "token",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "liquidity",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountTokenMin",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountETHMin",
              type: "uint256",
          },
          {
              internalType: "address",
              name: "to",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "deadline",
              type: "uint256",
          },
      ],
      name: "removeLiquidityETH",
      outputs: [
          {
              internalType: "uint256",
              name: "amountToken",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountETH",
              type: "uint256",
          },
      ],
      stateMutability: "nonpayable",
      type: "function",
  },
  {
      inputs: [
          {
              internalType: "address",
              name: "token",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "liquidity",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountTokenMin",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountETHMin",
              type: "uint256",
          },
          {
              internalType: "address",
              name: "to",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "deadline",
              type: "uint256",
          },
      ],
      name: "removeLiquidityETHSupportingFeeOnTransferTokens",
      outputs: [
          {
              internalType: "uint256",
              name: "amountETH",
              type: "uint256",
          },
      ],
      stateMutability: "nonpayable",
      type: "function",
  },
  {
      inputs: [
          {
              internalType: "address",
              name: "token",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "liquidity",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountTokenMin",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountETHMin",
              type: "uint256",
          },
          {
              internalType: "address",
              name: "to",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "deadline",
              type: "uint256",
          },
          {
              internalType: "bool",
              name: "approveMax",
              type: "bool",
          },
          {
              internalType: "uint8",
              name: "v",
              type: "uint8",
          },
          {
              internalType: "bytes32",
              name: "r",
              type: "bytes32",
          },
          {
              internalType: "bytes32",
              name: "s",
              type: "bytes32",
          },
      ],
      name: "removeLiquidityETHWithPermit",
      outputs: [
          {
              internalType: "uint256",
              name: "amountToken",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountETH",
              type: "uint256",
          },
      ],
      stateMutability: "nonpayable",
      type: "function",
  },
  {
      inputs: [
          {
              internalType: "address",
              name: "token",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "liquidity",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountTokenMin",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountETHMin",
              type: "uint256",
          },
          {
              internalType: "address",
              name: "to",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "deadline",
              type: "uint256",
          },
          {
              internalType: "bool",
              name: "approveMax",
              type: "bool",
          },
          {
              internalType: "uint8",
              name: "v",
              type: "uint8",
          },
          {
              internalType: "bytes32",
              name: "r",
              type: "bytes32",
          },
          {
              internalType: "bytes32",
              name: "s",
              type: "bytes32",
          },
      ],
      name: "removeLiquidityETHWithPermitSupportingFeeOnTransferTokens",
      outputs: [
          {
              internalType: "uint256",
              name: "amountETH",
              type: "uint256",
          },
      ],
      stateMutability: "nonpayable",
      type: "function",
  },
  {
      inputs: [
          {
              internalType: "address",
              name: "tokenA",
              type: "address",
          },
          {
              internalType: "address",
              name: "tokenB",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "liquidity",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountAMin",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountBMin",
              type: "uint256",
          },
          {
              internalType: "address",
              name: "to",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "deadline",
              type: "uint256",
          },
          {
              internalType: "bool",
              name: "approveMax",
              type: "bool",
          },
          {
              internalType: "uint8",
              name: "v",
              type: "uint8",
          },
          {
              internalType: "bytes32",
              name: "r",
              type: "bytes32",
          },
          {
              internalType: "bytes32",
              name: "s",
              type: "bytes32",
          },
      ],
      name: "removeLiquidityWithPermit",
      outputs: [
          {
              internalType: "uint256",
              name: "amountA",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountB",
              type: "uint256",
          },
      ],
      stateMutability: "nonpayable",
      type: "function",
  },
  {
      inputs: [
          {
              internalType: "uint256",
              name: "amountOut",
              type: "uint256",
          },
          {
              internalType: "address[]",
              name: "path",
              type: "address[]",
          },
          {
              internalType: "address",
              name: "to",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "deadline",
              type: "uint256",
          },
      ],
      name: "swapETHForExactTokens",
      outputs: [
          {
              internalType: "uint256[]",
              name: "amounts",
              type: "uint256[]",
          },
      ],
      stateMutability: "payable",
      type: "function",
  },
  {
      inputs: [
          {
              internalType: "uint256",
              name: "amountOutMin",
              type: "uint256",
          },
          {
              internalType: "address[]",
              name: "path",
              type: "address[]",
          },
          {
              internalType: "address",
              name: "to",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "deadline",
              type: "uint256",
          },
      ],
      name: "swapExactETHForTokens",
      outputs: [
          {
              internalType: "uint256[]",
              name: "amounts",
              type: "uint256[]",
          },
      ],
      stateMutability: "payable",
      type: "function",
  },
  {
      inputs: [
          {
              internalType: "uint256",
              name: "amountIn",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountOutMin",
              type: "uint256",
          },
          {
              internalType: "address[]",
              name: "path",
              type: "address[]",
          },
          {
              internalType: "address",
              name: "to",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "deadline",
              type: "uint256",
          },
      ],
      name: "swapExactTokensForETH",
      outputs: [
          {
              internalType: "uint256[]",
              name: "amounts",
              type: "uint256[]",
          },
      ],
      stateMutability: "nonpayable",
      type: "function",
  },
  {
      inputs: [
          {
              internalType: "uint256",
              name: "amountIn",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountOutMin",
              type: "uint256",
          },
          {
              internalType: "address[]",
              name: "path",
              type: "address[]",
          },
          {
              internalType: "address",
              name: "to",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "deadline",
              type: "uint256",
          },
      ],
      name: "swapExactTokensForTokens",
      outputs: [
          {
              internalType: "uint256[]",
              name: "amounts",
              type: "uint256[]",
          },
      ],
      stateMutability: "nonpayable",
      type: "function",
  },
  {
      inputs: [
          {
              internalType: "uint256",
              name: "amountOut",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountInMax",
              type: "uint256",
          },
          {
              internalType: "address[]",
              name: "path",
              type: "address[]",
          },
          {
              internalType: "address",
              name: "to",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "deadline",
              type: "uint256",
          },
      ],
      name: "swapTokensForExactETH",
      outputs: [
          {
              internalType: "uint256[]",
              name: "amounts",
              type: "uint256[]",
          },
      ],
      stateMutability: "nonpayable",
      type: "function",
  },
  {
      inputs: [
          {
              internalType: "uint256",
              name: "amountOut",
              type: "uint256",
          },
          {
              internalType: "uint256",
              name: "amountInMax",
              type: "uint256",
          },
          {
              internalType: "address[]",
              name: "path",
              type: "address[]",
          },
          {
              internalType: "address",
              name: "to",
              type: "address",
          },
          {
              internalType: "uint256",
              name: "deadline",
              type: "uint256",
          },
      ],
      name: "swapTokensForExactTokens",
      outputs: [
          {
              internalType: "uint256[]",
              name: "amounts",
              type: "uint256[]",
          },
      ],
      stateMutability: "nonpayable",
      type: "function",
  },
  {
      stateMutability: "payable",
      type: "receive",
  },
]