// // List of Tokens used in the Exchange_Page/Swap/Select_Token_Modal.


export const TokensList: {
    fullName: string;
    shortName: string;
    image?: any;
    key: number;
    address?: string;
}[] = [
    {
        fullName: "Binance",
        shortName: "BNB",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png',
        key: 1,
        address: "",
    },
    {
        fullName: "Dai",
        shortName: "DAI",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4943.png',
        key: 2,
        address: "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3",
    },
    {
        fullName: "Tether",
        shortName: "USDT",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
        key: 3,
        address: "0x55d398326f99059ff775485246999027b3197955",
    },
    {
        fullName: "USD Coin",
        shortName: "USDC",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3408.png',
        key: 4,
        address: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
    },
    {
        fullName: "BitTorrent-New",
        shortName: "BTT",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/16086.png',
        key: 5,
        address: "0x352Cb5E19b12FC216548a2677bD0fce83BaE434B", //mainnet
        // address: "0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735", //testnet
    },
    {
        fullName: "NEAR Protocol",
        shortName: "NEAR",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6535.png',
        key: 6,
        address: "0x1fa4a73a3f0133f0025378af00236f3abdee5d63",
    },
    {
        fullName: "Filecoin",
        shortName: "FIL",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2280.png',
        key: 7,
        address: "0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153",
    },
    // {
    //     fullName: "STEPN",
    //     shortName: "GMT",
    //     image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/18069.png',
    //     key: 8,
    //     address: "0x3019BF2a2eF8040C242C9a4c5c4BD4C81678b2A1",
    // },

    // {
    //     fullName: "IOTA",
    //     shortName: "IOTA",
    //     image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1720.png',
    //     key: 9,
    //     address: "0xd944f1d1e9d5f9bb90b62f9d45e447d989580782",
    // },
    {
        fullName: "Fantom",
        shortName: "FTM",
        image: "https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png",
        key: 10,
        address: "0xad29abb318791d579433d831ed122afeaf29dcfe",
    },
    {
        fullName: "Ankr",
        shortName: "ANKR",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3783.png',
        key: 11,
        address: "0xf307910a4c7bbc79691fd374889b36d8531b08e3",
    },
    {
        fullName: "IoTeX",
        shortName: "IOTX",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2777.png',
        key: 12,
        address: "0x9678e42cebeb63f23197d726b29b1cb20d0064e5",
    },
    {
        fullName: "1inch Network",
        shortName: "1INCH",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8104.png',
        key: 13,
        address: "0x111111111117dc0aa78b770fa6a738034120c302",
    },
    // {
    //     fullName: "PAX Gold",
    //     shortName: "PAXG",
    //     image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4705.png',
    //     key: 14,
    //     address: "0x7950865a9140cb519342433146ed5b40c6f210f7",
    // },
    {
        fullName: "Cosmos",
        shortName: "ATOM",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3794.png',
        key: 15,
        address: "0x0eb3a705fc54725037cc9e008bdede697f62f335",
    },
    // {
    //     fullName: "VeChain",
    //     shortName: "VET",
    //     image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3077.png',
    //     key: 16,
    //     address: "0x6FDcdfef7c496407cCb0cEC90f9C5Aaa1Cc8D888",
    // },
    {
        fullName: "Tezos",
        shortName: "XTZ",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2011.png',
        key: 17,
        address: "0x16939ef78684453bfdfb47825f8a5f714f12623a",
    },
    {
        fullName: "Aave",
        shortName: "AAVE",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7278.png',
        key: 18,
        address: "0xfb6115445bff7b52feb98650c87f44907e58f802",
    },
    {
        fullName: "Zcash",
        shortName: "ZEC",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1437.png',
        key: 19,
        address: "0x1ba42e5193dfa8b03d15dd1b86a3113bbbef8eeb",
    },
    {
        fullName: "Axie Infinity",
        shortName: "AXS",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6783.png',
        key: 20,
        address: "0x715d400f88c167884bbcc41c5fea407ed4d2f8a0",
    },
    {
        fullName: "Ethereum Classic",
        shortName: "ETC",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1321.png',
        key: 21,
        address: "0x3d6545b08693dae087e957cb1180ee38b9e3c25e",
    },
    {
        fullName: "Bitcoin Cash",
        shortName: "BCH",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1831.png',
        key: 22,
        address: "0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf",
    },
    {
        fullName: "Uniswap",
        shortName: "UNI",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7083.png',
        key: 23,
        address: "0xbf5140a22578168fd562dccf235e5d43a02ce9b1",
    },
    {
        fullName: "Litecoin",
        shortName: "LTC",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2.png',
        key: 24,
        address: "0x4338665cbb7b2485a8855a139b75d5e34ab0db94",
    },
    {
        fullName: "Polygon",
        shortName: "MATIC",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png',
        key: 25,
        address: "0xcc42724c6683b7e57334c4e856f4c9965ed682bd",
    },
    {
        fullName: "Avalanche",
        shortName: "AVAX",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png',
        key: 26,
        address: "0x1ce0c2827e2ef14d5c4f29a091d735a204794041",
    },
    {
        fullName: "TRON",
        shortName: "TRX",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1958.png',
        key: 27,
        address: "0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b",
    },
    {
        fullName: "Polkadot",
        shortName: "DOT",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6636.png',
        key: 28,
        address: "0x7083609fce4d1d8dc0c979aab8c869ea2c873402",
    },
    {
        fullName: "Dogecoin",
        shortName: "DOGE",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/74.png',
        key: 29,
        address: "0xba2ae424d960c26247dd6c32edc70b295c744c43",
    },
    {
        fullName: "Cardano",
        shortName: "ADA",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2010.png',
        key: 30,
        address: "0x3ee2200efb3400fabb9aacf31297cbdd1d435d47",
    },
    {
        fullName: "Binance USD",
        shortName: "BUSD",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4687.png',
        key: 31,
        address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    },
    {
        fullName: "Bitcoin BEP20",
        shortName: "BTCB",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/4023.png',
        key: 32,
        address: "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
    },
    {
        fullName: "Ethereum",
        shortName: "ETH",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
        key: 32,
        address: "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
    },
    {
        fullName: "Chainlink",
        shortName: "LINK",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png',
        key: 33,
        address: "0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd",
    },
    {
        fullName: "Elrond",
        shortName: "EGLD",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6892.png',
        key: 34,
        address: "0xbf7c81fff98bbe61b40ed186e4afd6ddd01337fe",
    },
    {
        fullName: "EOS",
        shortName: "EOS",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1765.png',
        key: 35,
        address: "0x56b6fb708fc5732dec1afc8d8556423a2edccbd6",
    },
    {
        fullName: "Maker",
        shortName: "MKR",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1518.png',
        key: 36,
        address: "0x5f0da599bb2cccfcf6fdfd7d81743b6020864350",
    },
    {
        fullName: "TrueUSD",
        shortName: "TUSD",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2563.png',
        key:37,
        address: "0x14016e85a25aeb13065688cafb43044c2ef86784",
    },
    {
        fullName: "PancakeSwap",
        shortName: "CAKE",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7186.png',
        key: 38,
        address: "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    },
    {
        fullName: "Venus",
        shortName: "XVS",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7288.png',
        key: 39,
        address: "0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63",
    },
    // {
    //     fullName: "Zilliqa",
    //     shortName: "ZIL",
    //     image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2469.png',
    //     key: 40,
    //     address: "0xb86abcb37c3a4b64f74f59301aff131a1becc787",
    // },
    {
        fullName: "Floki Inu",
        shortName: "FLOKI",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10804.png',
        key: 41,
        address: "0xfb5b838b6cfeedc2873ab27866079ac55363d37e",
    },
    {
        fullName: "Beta Finance",
        shortName: "BETA",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11307.png',
        key: 42,
        address: "0xbe1a001fe942f96eea22ba08783140b9dcc09d28",
    },
    {
        fullName: "Tranchess",
        shortName: "CHESS",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10974.png',
        key: 43,
        address: "0x20de22029ab63cf9A7Cf5fEB2b737Ca1eE4c82A6",
    },
    // {
    //     fullName: "TORG",
    //     shortName: "TORG",
    //     image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/11820.png',
    //     key: 44,
    //     address: "0xcea59dce6a6d73a24e6d6944cfabc330814c098a",
    // },
    {
        fullName: "XRP",
        shortName: "XRP",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/52.png',
        key: 45,
        address: "0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe",
    },
    {
        fullName: "USDD",
        shortName: "USDD",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/19891.png',
        key: 46,
        address: "0xd17479997f34dd9156deef8f95a52d81d265be9c",
    },
    // {
    //     fullName: "Maker",
    //     shortName: "MKR",
    //     image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1518.png',
    //     key: 47,
    //     address: "0x5f0da599bb2cccfcf6fdfd7d81743b6020864350",
    // },
    {
        fullName: "MyNeighborAlice",
        shortName: "ALICE",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/8766.png',
        key: 48,
        address: "0xac51066d7bec65dc4589368da368b212745d63e8",
    },
    {
        fullName: "Basic Attention Token",
        shortName: "BAT",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1697.png',
        key: 49,
        address: "0x101d82428437127bf1608f699cd651e6abf9766e",
    },
    {
        fullName: "Baby Doge Coin",
        shortName: "BabyDoge",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10407.png',
        key: 50,
        address: "0xc748673057861a797275cd8a068abb95a902e8de",
    },
    {
        fullName: "Smooth Love Potion",
        shortName: "SLP",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5824.png',
        key: 51,
        address: "0x070a08BeEF8d36734dD67A491202fF35a6A16d97",
    },
    // {
    //     fullName: "Chromia",
    //     shortName: "CHR",
    //     image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3978.png',
    //     key: 52,
    //     address: "0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE",
    // },
    {
        fullName: "SushiSwap",
        shortName: "SUSHI",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/6758.png',
        key: 53,
        address: "0x947950BcC74888a40Ffa2593C5798F11Fc9124C4",
    },
    // {
    //     fullName: "Neutrino USD",
    //     shortName: "USDN",
    //     image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5068.png',
    //     key: 54,
    //     address: "0x03ab98f5dc94996f8c33e15cd4468794d12d41f9",
    // },
    {
        fullName: "Coin98",
        shortName: "C98",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/10903.png',
        key: 55,
        address: "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6",
    },
    {
        fullName: "BakeryToken",
        shortName: "BAKE",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7064.png',
        key: 56,
        address: "0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5",
    },
    {
        fullName: "Wrapped BNB",
        shortName: "WBNB",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7192.png',
        key: 57,
        address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    },
    {
        fullName: "Synthetix",
        shortName: "SNX",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2586.png',
        key: 58,
        address: "0x9ac983826058b8a9c7aa1c9171441191232e8404",
    },
    {
        fullName: "Pax Dollar",
        shortName: "USDP",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3330.png',
        key: 59,
        address: "0xb7f8cd00c5a06c0537e2abff0b58033d02e5e094",
    },
    {
        fullName: "TerraClassicUSD",
        shortName: "USTC",
        image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/7129.png',
        key: 60,
        address: "0x23396cf899ca06c4472205fc903bdb4de249d6fc",
    },
    // {
    //     fullName: "Shiba Inu",
    //     shortName: "SHIB",
    //     image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5994.png',
    //     key: 61,
    //     address: "0xb1547683DA678f2e1F003A780143EC10Af8a832B",
    // },
    // {
    //     fullName: "",
    //     shortName: "",
    //     image: '',
    //     key: 0,
    //     address: "",
    // },
    // {
    //     fullName: "",
    //     shortName: "",
    //     image: '',
    //     key: 0,
    //     address: "",
    // },
];
