import React from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    color: "white !important",
  },
}));

export default function LoadingIndicator({ size = 14, thickness = 7.2 }) {
  const classes = useStyles();

  return (
    <CircularProgress
      className={classes.root}
      size={size}
      thickness={thickness}
    />
  );
}
