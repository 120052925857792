import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './container/home'
import Management from '../management/Management'

export default function RoutesLayout() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/management" element={<Management />} />
        </Routes>
    )
}
