import * as React from "react";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import Button from "../common/button";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import Input from "../common/input";
import {
  CardContent,
  Typography,
  Card,
  Box,
  Container,
  MenuItem,
  Menu,
} from "@mui/material";
import theme from "../layout/theme";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "../common/iconButton";
import { BlockchainStateContext } from "../../context/BlockchainContext";
import { ethers } from "ethers";
import {
  daiAddress,
  icoAbi,
  icoAddress,
  routerAbi,
  routerAddress,
  tokenAbi,
  tokenAddress,
  usdcAddress,
  usdtAddress,
  wethAddress,
} from "../../wallet/abi";
import { toEther, toWei } from "../../wallet/utils";
import axios from "axios";
import _ from "lodash";
import Dialog from "../common/dialog";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { selectedColor } from "../../app/uiSlice";
import { useAppSelector } from "../../app/hooks";
import { Provider } from "react-redux";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { TokensList } from "./tokenList";
import { ChainId, Token, WETH, Fetcher, Route, Pair, TokenAmount, Trade, TradeType } from "@pancakeswap/sdk";
import isTradeBetter, { BETTER_TRADE_LESS_HOPS_THRESHOLD } from "../../hooks/isBetter";

const options = ["BNB", "USDT", "DAI", "USDC"];

const useStyles = makeStyles({
  root: {
    justifyContent: "center",
    minHeight: "30em",
    overflow: "hidden",
    position: "relative",
    textAlign: "center",
    alignItems: "center",
    height: "auto",
    borderRadius: 5,
  },
  select: {
    "&:hover": {
      border: "none !important",
    },
  },
  list: {
    "& .css-10n99xx-MuiButtonBase-root-MuiMenuItem-root:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.light,
    },
    borderRadius: theme.spacing(1),
    border: "1px solid",
    borderColor: theme.palette.secondary.light,
    background: theme.palette.primary.main,
    flex: 1,
    margin: theme.spacing(0, 1, 0, 1),
    overflowX: "hidden",
    overflowY: "auto",
  },
  image: {
    transition: "transform .4s",
    "&:hover": {
      transform: "scale(1.03)",
    },
  },
  button: {
    color: "white",
    padding: "10px 24px",
    borderColor: theme.palette.secondary.light,
    background: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    border: "1px solid",
    margin: theme.spacing(0, 1, 0, 1),
    "&:hover": {
      border: "2px solid",
    },
  },
});

const ITEM_HEIGHT = 48;

function BuyToken() {
  const classes = useStyles();

  const BNBPriceApi =
    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=10&page=1&sparkline=false";
  const tokenPriceApi =
    "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=500&page=1&sparkline=false";
  const [selectedToken, setSelectedToken] = React.useState<string>("USDT");
  const [selectedTokenFullname, setSelectedTokenFullname] =
    React.useState<string>("USDT");
  const uiStatus = useAppSelector(selectedColor);
  const [search, setSearch] = React.useState("");
  const [amount, setAmount] = React.useState<string>("1");
  const [BNBPrice, setBNBPrice] = React.useState<any>();
  const [tokenAmountOut, setTokenAmountOut] = React.useState<any>(25);
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<boolean>(false);
  const [copied, setCopied] = React.useState<boolean>(false);
  const [textError, setTextError] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedTokenAddress, setSelectedTokenAddress] =
    React.useState<any>("");
  const [middleTokenAddress, setMiddleTokenAddress] =
    React.useState<any>();
  const [waitText, setWaitText] = React.useState(false)
  const open = Boolean(anchorEl);
  const { referAddress } = React.useContext(BlockchainStateContext);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setSearch("")
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { library, account } = React.useContext(BlockchainStateContext);

  const countBoc = async () => {
    setTokenAmountOut(0);
    if (selectedToken == "BNB" || selectedToken == "WBNB") {
      const tokenAmountOut = (Number(amount) * BNBPrice) / 0.04;
      setTokenAmountOut(tokenAmountOut);
      return (Number(amount) * BNBPrice) / 0.04;
    }
    if (
      selectedToken == "DAI" ||
      selectedToken == "USDC" ||
      selectedToken == "USDT"
    ) {
      setTokenAmountOut(Number(amount) / 0.04);
      return Number(amount) / 0.04;
    } else {
      if (library) {
        try {
          setWaitText(true)
          await showTokenPrice()
          setWaitText(false)
          let amountsOut;
          if(middleTokenAddress !== ""){
            const path = [
              selectedTokenAddress,
              middleTokenAddress,
              wethAddress
            ]
            const tokenContract = new ethers.Contract(
              selectedTokenAddress,
              tokenAbi,
              library
            );
            const routerContract = new ethers.Contract(routerAddress, routerAbi, library)

            const decimals = await tokenContract.decimals()
            console.log("decimals2 :",decimals)
            const finalAmount = (Number(amount)*10**decimals).toLocaleString('fullwide', {useGrouping:false}) 
            console.log("amountIn :",finalAmount)
            const showAmountsOut = await routerContract.getAmountsOut(finalAmount, path)
            amountsOut = showAmountsOut[2]
            console.log("amountsOut3 :", toEther(showAmountsOut[2]))
          }else{
            const path = [
              selectedTokenAddress,
              wethAddress
            ]

            const tokenContract = new ethers.Contract(
              selectedTokenAddress,
              tokenAbi,
              library
            );

            const routerContract = new ethers.Contract(routerAddress, routerAbi, library)
            const decimals = await tokenContract.decimals()
            console.log("decimals2 :",decimals)
            const finalAmount = (Number(amount)*10**decimals).toLocaleString('fullwide', {useGrouping:false}) 
            console.log("amountIn :",finalAmount)
            const showAmountsOut = await routerContract.getAmountsOut(finalAmount, path)
            amountsOut = showAmountsOut[1]
            console.log("amountsOut :", toEther(showAmountsOut[1]))
          }
          // const icoContract = new ethers.Contract(icoAddress, icoAbi, library);
          // const amountsOut = await icoContract.getAmountsOut(
          //   selectedTokenAddress,
          //   toWei(amount)
          // );
          // const isOk = await showTokenPrice()
          // console.log(toEther(amountsOut));
          // if (amountsOut > 0 && isOk) {
          if (amountsOut > 0) {
            const tokenAmountOut = (amountsOut * BNBPrice) / 0.04;
            setTokenAmountOut(tokenAmountOut / 10 ** 18);
            return Number(tokenAmountOut / 10 ** 18);
          } else {
            setShowError(true);
            setTextError(`We cant support ${selectedToken} now`);
            setTokenAmountOut(0);
            return 0;
          }
        } catch (error) {
          setTokenAmountOut(0);
          //   setShowError(true);
          //   setTextError(`We cant support ${selectedToken} now`);
        }
      }
      return Number(-1);
    }
  };

  const filterTokens = TokensList.filter((token) => {
    const fullName = token.fullName
      .toLowerCase()
      .includes(search.toLowerCase());
    const symbol = token.shortName.toUpperCase().includes(search.toUpperCase());
    if (fullName) {
      return fullName;
    } else {
      return symbol;
    }
  });

  const selectToken = (
    tokenFullname: any,
    tokenName: any,
    tokenAddress: any
  ) => {
    setSelectedTokenFullname(tokenFullname);
    setSelectedToken(tokenName);
    setSelectedTokenAddress(tokenAddress);
  };

  const buy = async (currencyName: string, amount: any, bnbPrice: string) => {
    try {
      if (library) {
        const am = await countBoc();

        if (am < 1000) {
          setShowError(true);
          if (am < 1000) {
            setTextError("The minmum amount of boc that you can buy is 1000");
          }
        } else {
          setLoading(true);

          if (currencyName === "BNB" || currencyName === "WBNB" ) {
            const icoContract = new ethers.Contract(
              icoAddress,
              icoAbi,
              library
            );
            const signer = library.getSigner();
            // console.log(parseInt(await icoContract.getLatestPrice()))
            // return
            const res = await icoContract
              .connect(signer)
              .buy(referAddress, { value: toWei(amount), gasLimit: 4000000 });
            const receipt = await res.wait();

            if (receipt.status == 1) {
              setLoading(false);
              setShowDialog(true);
              setAmount("1");
              setSelectedToken("USDT");
            } else {
              setLoading(false);
              setAmount("1");
              setSelectedToken("USDT");
            }
          }

          if (currencyName === "USDT") {
            const usdtContract = new ethers.Contract(
              usdtAddress,
              tokenAbi,
              library
            );
            const icoContract = new ethers.Contract(
              icoAddress,
              icoAbi,
              library
            );
            const signer = library.getSigner();
            await usdtContract
              .connect(signer)
              .approve(icoAddress, toWei(amount));
            const res = await icoContract
              .connect(signer)
              .buyWithToken(referAddress, usdtAddress, toWei(amount), {
                gasLimit: 400000,
              });
            const receipt = await res.wait();

            if (receipt.status == 1) {
              setLoading(false);
              setShowDialog(true);
              setAmount("1");
              setSelectedToken("USDT");
            } else {
              setLoading(false);
              setAmount("1");
              setSelectedToken("USDT");
            }
          }

          if (currencyName === "USDC") {
            const usdcContract = new ethers.Contract(
              usdcAddress,
              tokenAbi,
              library
            );
            const icoContract = new ethers.Contract(
              icoAddress,
              icoAbi,
              library
            );
            const signer = library.getSigner();
            await usdcContract
              .connect(signer)
              .approve(icoAddress, toWei(amount));
            const res = await icoContract
              .connect(signer)
              .buyWithToken(referAddress, usdcAddress, toWei(amount), {
                gasLimit: 400000,
              });
            const receipt = await res.wait();

            if (receipt.status == 1) {
              setLoading(false);
              setShowDialog(true);
              setAmount("1");
              setSelectedToken("USDT");
            } else {
              setLoading(false);
              setAmount("1");
              setSelectedToken("USDT");
            }
          }

          if (currencyName === "DAI") {
            const daiContract = new ethers.Contract(
              daiAddress,
              tokenAbi,
              library
            );
            const icoContract = new ethers.Contract(
              icoAddress,
              icoAbi,
              library
            );
            const signer = library.getSigner();
            await daiContract
              .connect(signer)
              .approve(icoAddress, toWei(amount));
            const res = await icoContract
              .connect(signer)
              .buyWithToken(referAddress, daiAddress, toWei(amount), {
                gasLimit: 400000,
              });
            const receipt = await res.wait();

            if (receipt.status == 1) {
              setLoading(false);
              setShowDialog(true);
              setAmount("1");
              setSelectedToken("USDT");
            } else {
              setLoading(false);
              setAmount("1");
              setSelectedToken("USDT");
            }
          } else {
            const tokenContract = new ethers.Contract(
              selectedTokenAddress,
              tokenAbi,
              library
            );
            const icoContract = new ethers.Contract(
              icoAddress,
              icoAbi,
              library
            );

            let secondToken;
            if(middleTokenAddress){
              secondToken = middleTokenAddress
              console.log(secondToken)
            }else{
              secondToken = wethAddress
            }

            const decimals = await tokenContract.decimals()
            console.log("desimals:", decimals)
            const finalAmount = (Number(amount)*10**decimals).toLocaleString('fullwide', {useGrouping:false}) 
            console.log("amountIn :",finalAmount)
            const howmuch = await icoContract.getAmountsOut(selectedTokenAddress, secondToken, finalAmount)
            console.log("How much :", toEther(howmuch));
            
            const signer = library.getSigner();


            await tokenContract
              .connect(signer)
              .approve(icoAddress, finalAmount);
            const res = await icoContract
              .connect(signer)
              .buyWithAllTokens2(selectedTokenAddress,secondToken, finalAmount, {
                gasLimit: 400000,
              });
            const receipt = await res.wait();

            if (receipt.status == 1) {
              setLoading(false);
              setShowDialog(true);
              setAmount("1");
              setSelectedToken("USDT");
            } else {
              setLoading(false);
              setAmount("1");
              setSelectedToken("USDT");
            }
          }
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // console.log("HHH",window.innerHeight)

  const onChange = (e: any) => {
    if (e?.target?.type === "number") {
      if (e.target.value < 0) return;
      setAmount(e.target.value);
    } else {
      setSelectedToken(e);
      setLoading(false);
      handleClose();
    }
  };

  const checkPrice = async () => {
    if (library) {
      const icoContract = new ethers.Contract(icoAddress, icoAbi, library);
      const signer = library.getSigner();
      const price = parseInt(await icoContract.getLatestPrice());
      // console.log("feedPrice :", price);
      setBNBPrice(price);
    } else {
      axios.get(BNBPriceApi).then((res) => {
        const datas = res.data;
        for (const data of datas) {
          if (data.symbol == "bnb" && !library) {
            setBNBPrice(_.toString(data.current_price));
            return;
          }
        }
      });
    }
  };

  const showTokenPrice = async () => {
    // setMiddleTokenAddress("")
    if (!selectedTokenAddress || selectedToken == "BUSD" || selectedToken == "USDT" || selectedToken == "DAI" || selectedToken == "USDC"|| selectedToken == "WBNB") return;
    try {
      
    const Tok = new Token(56, selectedTokenAddress, 18);
    const busd = new Token(56,'0xe9e7cea3dedca5984780bafc599bd69add087d56', 18);
    const usdt = new Token(56,'0x55d398326f99059ff775485246999027b3197955', 18);
    const usdc = new Token(56,'0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d', 18);
    const ethh = new Token(56,'0x2170ed0880ac9a755fd29b2688956bd959f933f8', 18);
    const toketh = await Fetcher.fetchPairData(Tok, WETH[Tok.chainId], library);
    const tokbusd = await Fetcher.fetchPairData(Tok, busd, library);
    const busdeth = await Fetcher.fetchPairData(busd, WETH[Tok.chainId], library);
    const tokusdt = await Fetcher.fetchPairData(Tok, usdt, library);
    const usdteth = await Fetcher.fetchPairData(usdt, WETH[Tok.chainId], library);
    // const tokusdc = await Fetcher.fetchPairData(Tok, usdc, library)
    const usdceth = await Fetcher.fetchPairData(usdc, WETH[Tok.chainId], library);
    // const tokethh = await Fetcher.fetchPairData(Tok, ethh, library)
    const ethheth = await Fetcher.fetchPairData(ethh, WETH[Tok.chainId], library);
    // return
    const allPairs = [
      toketh,
      tokbusd,
      busdeth,
      tokusdt,
      usdteth,
      // tokusdc,
      usdceth,
      // tokethh,
      ethheth
    ]
    
    const amountAut = new TokenAmount(WETH[Tok.chainId], '1000000000000000000')
    let bestTradeSoFar: Trade | null = null
    for (let i = 1; i <= 3; i++) {
      const currentTrade =
        Trade.bestTradeExactOut(allPairs, Tok, amountAut, { maxHops: i, maxNumResults: 1 })[0] ??
        null
      if (isTradeBetter(bestTradeSoFar, currentTrade, BETTER_TRADE_LESS_HOPS_THRESHOLD)) {
        bestTradeSoFar = currentTrade
      }
    }


      console.log("bestTradeSoFar :", bestTradeSoFar?.route?.path[1].address)
      if(bestTradeSoFar?.route?.path.length !== 3){
        setMiddleTokenAddress("")
        return
      }
      const middleAddress = bestTradeSoFar?.route?.path[1].address;
      setMiddleTokenAddress(middleAddress)
      return


    } catch (error) {
      return
    }
    //   if(middleAddress !== wethAddress){
    //   const path = [
    //     selectedTokenAddress,
    //     middleAddress,
    //     wethAddress
    //   ]
    //   const routerContract = new ethers.Contract(routerAddress, routerAbi, library)
    //   const amountsOut = await routerContract.getAmountsOut(toWei("1"), path)
    //   console.log("amountsOut :", toEther(amountsOut[2]))
    // }
      // console.log(allPairs)
      // const route = new Route([toketh], WETH[Tok.chainId]);
    // console.log("AAA",route.midPrice.toSignificant(6)) // 201.306
    // const trade = new Trade(route, new TokenAmount(WETH[DAI.chainId], '1000000000000000000'), TradeType.EXACT_INPUT)
    // const BBB = route.midPrice.invert().toSignificant(6); // 0.00496756
    // const BBB1 = trade.executionPrice.invert().toSignificant(6); // 0.00496756
    // console.log("real price1 :",BBB);
    // console.log("real price2 :",BBB1);
    // const icoContract = new ethers.Contract(icoAddress, icoAbi, library);
    // const amountsOut = await icoContract.getAmountsOut(
    //   selectedTokenAddress,
    //   toWei("1")
    // );
    // console.log("amounts out per bnb :", amountsOut / 10 ** 18);
    
    // const bool = amountsOut / 10 ** 18 / Number(BBB);
    // console.log("bool :", bool);
    // if (0.9 < bool && bool < 1.1) {
    //   return true
    // } else {
    //   return false;
    // }
  };

  const addToken = async () => {
    await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: tokenAddress,
          symbol: "BOC",
          decimals: 18,
        },
      },
    });
  };

  React.useEffect(() => {
    checkPrice();
  }, [library, account]);

  React.useEffect(() => {
    countBoc();
  }, [selectedToken, amount]);

  React.useEffect(() => {
    showTokenPrice();
    
  }, [selectedToken]);

  return (
    <Container maxWidth="xl">
      <Card
        sx={{
          backgroundColor: "transparent",
        }}
      >
        <CardContent
          sx={{
            marginBottom: theme.spacing(3),
            border: "1px solid",
            borderColor: uiStatus === "dark" ? "white" : "black",
            borderRadius: theme.spacing(1),
          }}
        >
          <Grid
            container
            spacing={5}
            className={classes.root}
            id="bytoken"
            sx={{ mt: 8 }}
          >
            <Grid
              item
              sm={6}
              xs={12}
              alignItems={"center"}
              justifyContent="center"
              display={"flex"}
            >
              <Card
                sx={{ width: "100%", background: "none", boxShadow: "none" }}
              >
                <CardContent>
                  <Typography
                    component="h4"
                    variant="h4"
                    align="left"
                    color={uiStatus === "dark" ? "white" : "black"}
                    gutterBottom
                  >
                    Buy BOC
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    color={uiStatus === "dark" ? "white" : "black"}
                    component="p"
                    mr={1}
                  >
                    Boc will be the first supported cryptocurrency in the world.
                    It’s backing will contain economic activities and precious
                    metals.
                    <br />
                    Join us on the path to globalization
                    <br />
                    *Make sure to use Google Chrome
                    <br />
                    1: First, place your wallet on Binance Smart chain Network
                    <br />
                    2: Copy the Boc token contract address through the box below
                    and import it into your wallet.
                    <br />
                    3: Click on “connect wallet” at the top right of the page
                    and select your desired wallet to buy Boc currency.
                    <br />
                    * Note the minimum purchase is 1000 Boc.
                    <br />
                    * To view the instructional video of buying via desktop and
                    mobile, refer to the following link :<br />
                    <a
                      style={{ color: "#668ac4" }}
                      href="http://bocoin.info/training/"
                      target="_blank"
                    >
                      http://bocoin.info/training/
                    </a>
                    <br />
                    <br />
                    Copy Boc Contract address :<br />
                    <a
                      style={{ color: "#668ac4" }}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          "0x80e7dc4e726E052b0dB04ec8b506596458809c11"
                        );
                        setCopied(true);
                      }}
                    >
                      0x80e7dc4e726E052b0dB04ec8b506596458809c11
                    </a>
                    &nbsp;
                    <ContentCopyIcon
                      fontSize="small"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          "0x80e7dc4e726E052b0dB04ec8b506596458809c11"
                        );
                        setCopied(true);
                      }}
                    />
                    <br />
                    {copied ? "Address copied!" : null}
                    {window.innerHeight > 425 && window.innerWidth > 768 ? (
                      <>
                        <br />
                        <br />
                        Add Boc to metamask : &nbsp;
                        <button className={classes.button} onClick={addToken}>
                          ADD
                        </button>
                      </>
                    ) : null}
                  </Typography>
                  <br />
                  <br />

                  <Typography
                    component="h4"
                    variant="h4"
                    align="left"
                    color={uiStatus === "dark" ? "white" : "black"}
                    gutterBottom
                  >
                    Get it now while it's cheap
                  </Typography>
                  <Input
                    type="number"
                    min="0"
                    value={amount}
                    onChange={(e: string) => onChange(e)}
                    endAdornment={
                      <Box display={"flex"} alignItems={"center"}>
                        <Typography
                          color={uiStatus === "dark" ? "white" : "black"}
                          display={"flex"}
                          variant="body2"
                        >
                          {selectedToken}
                        </Typography>
                        <IconButton
                          aria-label="more"
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={handleClick}
                          icon={<MoreVertIcon />}
                        />
                        {/* <Menu
                                                    classes={{ list: classes.list }}
                                                    id="long-menu"
                                                    MenuListProps={{
                                                        'aria-labelledby': 'long-button',
                                                    }}
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    PaperProps={{
                                                        style: {
                                                            maxHeight: ITEM_HEIGHT * 4.5,
                                                            width: '20ch',
                                                        },
                                                    }}
                                                >
                                                    {options.map((option, key) => (
                                                        <MenuItem key={key} selected={selectedToken === 'USDT'} onClick={() => onChange(option)}>
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </Menu> */}
                      </Box>
                    }
                  />
                  <Typography
                    color={uiStatus === "dark" ? "yellow" : "darkgray"}
                    display={"flex"}
                    variant="body2"
                  >
                    {account && !waitText ? `You wil get : ${tokenAmountOut} boc` : null}
                    {waitText && "Please wait ..."}
                  </Typography>
                  <br />
                  <br />

                  <Typography
                    color={uiStatus === "dark" ? "white" : "black"}
                    display={"d-flex"}
                    variant="body2"
                  >
                    1 boc = {Number(0.04 / BNBPrice).toFixed(6)} BNB
                  </Typography>
                  <Typography
                    color={uiStatus === "dark" ? "white" : "black"}
                    display={"d-flex"}
                    variant="body2"
                  >
                    1 boc = 0.04 USD
                  </Typography>
                </CardContent>
                <Box
                  display={"row"}
                  justifyContent={"space-between"}
                  padding={2}
                >
                  <Typography
                    color={uiStatus === "dark" ? "white" : "black"}
                    display={"flex"}
                    variant="body2"
                  >
                    Minimum purchase: 1000 boc
                  </Typography>
                  <Box display={"flex"} justifyContent={"right"} padding={2}>
                    <Button
                      title="buy"
                      loading={loading}
                      startIcon={<ShoppingCartRoundedIcon />}
                      onClick={() => buy(selectedToken, amount, BNBPrice)}
                    />
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item sm={6} xs={12}>
              <img
                className={classes.image}
                src={"/images/Overcome_bro.png"}
                alt="Asset"
                height="70%"
                width="70%"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        header={"Buy Token"}
        content={
          <Typography
            variant="h4"
            align="center"
            color={uiStatus === "dark" ? "white" : "black"}
            gutterBottom
            component="p"
          >
            {"You successfully buy token"}
          </Typography>
        }
        actions={
          <Button
            onClick={() => setShowDialog(false)}
            title="ok"
            startIcon={<CheckRoundedIcon />}
          />
        }
      />

      <Dialog
        open={showError}
        onClose={() => setShowError(false)}
        header={"Buy Token"}
        content={
          <Typography
            variant="h4"
            align="center"
            color={uiStatus === "dark" ? "white" : "black"}
            gutterBottom
            component="p"
          >
            {textError}
          </Typography>
        }
        actions={
          <Button
            onClick={() => setShowError(false)}
            title="ok"
            startIcon={<CheckRoundedIcon />}
          />
        }
      />

      <Dialog
        open={open}
        onClose={handleClose}
        header={"Select token"}
        content={
          <Box>
            <Typography
              // variant="h7"
              align="center"
              // color={uiStatus === "dark" ? "white" : "black"}
              color={"black"}
              gutterBottom
              component="p"
            >
              <Input
                onChange={(e: any) => setSearch(e.target.value)}
                label="Search token"
              />
            </Typography>
            <Typography
              variant="h4"
              align="center"
              color={uiStatus === "dark" ? "white" : "black"}
              gutterBottom
              component="p"
              style={{ maxHeight: "15rem", paddingTop: "1rem" }}
            >
              {filterTokens.map((option, key) => (
                <MenuItem
                  key={key}
                  selected={selectedToken === "USDT"}
                  onClick={() => {
                    selectToken(
                      option.fullName,
                      option.shortName,
                      option.address
                    );
                    onChange(option.shortName);
                  }}
                >
                  <img
                    src={option.image}
                    style={{ width: "2rem", height: "2rem" }}
                  />
                  <Typography component={"span"} variant={"body2"}>
                    &nbsp;{option.fullName}&nbsp;{option.shortName}
                  </Typography>
                </MenuItem>
              ))}
            </Typography>
          </Box>
        }
      />
    </Container>
  );
}

export default BuyToken;
