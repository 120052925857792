import React from 'react'
import Countdown from 'react-countdown'
import { makeStyles } from '@mui/styles';
import { selectedColor } from '../../app/uiSlice';
import { useAppSelector } from '../../app/hooks';

const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        fontFamily: "montserrat",
    },
    topicStyle: {
        fontSize: "2rem",
        fontWeight: 800,
    },
    days: {
        fontSize: "0.88rem",
    },
});

interface Props {
    startTime: any;
}

const CoundDownStart = React.memo((props: Props) => {
    const classes = useStyles();

    const uiStatus = useAppSelector(selectedColor);
    const zeroPad = (input: any) => String(input).padStart(2, "0");

    interface RendererRemainInterface {
        days: any;
        hours: any;
        minutes: any;
        seconds: any;
        completed: any
    }

    const rendererRemain = (props: RendererRemainInterface) => {
        if (props.completed) {
            return null;
        } else {
            return (
                <div className={classes.container}
                    style={{
                        color: uiStatus === "dark" ? "white" : "black"

                    }}
                >
                    <span style={{ fontFamily: "monospace" }}>
                        {props.days > 0 && (
                            <>
                                <span className={classes.topicStyle}>{props.days}</span>
                                <span className={classes.days}>{" days"} </span>
                            </>
                        )}
                        <span className={classes.topicStyle}>{zeroPad(props.hours)}</span>
                        <span className={classes.topicStyle}> : </span>
                        <span className={classes.topicStyle}>{zeroPad(props.minutes)}</span>
                        <span className={classes.topicStyle}> : </span>
                        <span className={classes.topicStyle}>{zeroPad(props.seconds)}</span>
                    </span>
                </div>
            );
        }
    };

    return (
        <Countdown
            date={(props.startTime * 1000)}
            renderer={rendererRemain}
        />
    )
}
)

export default CoundDownStart;